import React, {useState} from 'react';
import PropTypes from 'prop-types';

const QuantityInput = ({disabled, onChange, initialValue}) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => {
        setValue(Math.max(1, Number(e.target.value)));
        onChange(Math.max(1, Number(e.target.value)));
    };
    return (
        <div className="cart_quantity-input">
            <button className="btn btn-link" disabled={disabled || value <= 1} onClick={() => handleChange({target: {value: Number(value) - 1}})}>–</button>
            <input value={value} type="number" step="1" min="1" onChange={handleChange} disabled={disabled} />
            <button className="btn btn-link" disabled={disabled} onClick={() => handleChange({target: {value: Number(value) + 1}})}>+</button>
        </div>
    );
};

QuantityInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.number.isRequired,
};

export default QuantityInput;
