import React from 'react';

const JackPreview = ({height, width, placeholders, ratio}) => (
    <div className="jack-preview" style={{height: height * ratio, width: width * ratio}}>
        {placeholders.map(({area}, index) => <span key={index} className="jack-preview__placeholder"
            style={{
                left: `${area[0] / width * 100}%`, top: `${area[1] / height * 100}%`,
                width: `${area[2] / width * 100}%`, height: `${area[3] / height * 100}%`}} />)}
    </div>
);

export default JackPreview;
