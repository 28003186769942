import React from 'react';
import {Link} from 'react-router-dom';
import CartLayout from './CartLayout';

const CartEmpty = () => (
    <CartLayout>
        <table className="table cart-table">
            <thead>
                <tr>
                    <th scope="col">Anteprima</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Quantità</th>
                    <th scope="col">Modifica</th>
                    <th scope="col">Elimina</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div className="mt-5 alert alert-warning" role="alert">
                            Il tuo archivio è vuoto!
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="5">
                        <Link to="/ispirazioni" className="btn btn-secondary btn-ave">
                            TORNA ALLA PAGINA SELEZIONA DESIGN E MATERIALE
                        </Link>
                    </td>
                </tr>
            </tfoot>
        </table>
    </CartLayout>
);

export default CartEmpty;
