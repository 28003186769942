import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class StageLink extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.stage);
    }

    render() {
        const {active, disabled, children, className} = this.props;
        return (
            <button onClick={this.handleClick} className={classNames('stage-link btn btn-link', className, {active})} disabled={disabled}>
                {children}
            </button>
        );
    }
}

StageLink.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    stage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

export default StageLink;
