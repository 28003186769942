import React from 'react';
import {useTranslation} from "react-i18next";
import DraggableImageItem from './DraggableImageItem';
import {SUBSTAGES} from '../../../constants';

const LibraryPicker = ({items, substage, id, label, ...rest}) => {
    const {t} = useTranslation();
    return (
        <div {...rest}>
            <div className="label">
                {t(substage === SUBSTAGES.CONTENT_LASER ? "instructions.laser" : "instructions.print")}<br/>
                <strong>{t('instructions.trascina')}</strong>
            </div>
            {label && <span className="palette-label">RGB: {label}</span>}
            <div className="palette-list" id={id}>
                {items.map((src) => <DraggableImageItem src={src} key={src} height={48} />)}
            </div>
        </div>
    );
};

export default LibraryPicker;
