import React from 'react';
import {useTranslation} from "react-i18next";
import {getCartItemThumbnailUrl} from '../../api';
import * as images from '../../images';
import QuantityInput from './QuantityInput';

const deleteItem = (id, cb) => confirm('Sei sicuro?') && cb(id); // eslint-disable-line no-restricted-globals

const CartItem = ({cartCode, disabled, id, plate, quantity, note, onUpdateItem, onDeleteItem, onEditItem}) => {
    const {t} = useTranslation();
    return (
        <tr>
            <td><img src={getCartItemThumbnailUrl(cartCode, id)} alt={t("cart.preview")} width="100"/></td>
            <td className="text-left"><strong>{plate}</strong></td>
            <td><QuantityInput initialValue={quantity} onChange={(quantity) => onUpdateItem(id, {quantity})} disabled={disabled} /></td>
            <td><small>{note}</small></td>
            {!disabled && <td>
                <a className="btn btn-link text-body font-weight-bold text-left cart-item-action"
                    title={t("instructions.modifica")}
                    href={`/configuratore/${encodeURIComponent(plate)}/modifica/${encodeURIComponent(id)}`}>
                    <img src={images.pencil} alt={t("buttons.edit")} /> {t("buttons.modificaPersonalizzazione")}
                </a><br />
                <a className="btn btn-link text-body font-weight-bold text-left cart-item-action"
                    title={t("instructions.clona")}
                    href={`/configuratore/${encodeURIComponent(plate)}/clona/${encodeURIComponent(id)}`}>
                    <img src={images.pencil} alt={t("buttons.clone")} /> {t("buttons.clonaPersonalizzazione")}
                </a>
            </td>}
            {!disabled && <td>
                <button className="col btn btn-link" onClick={() => deleteItem(id, onDeleteItem)}>
                    <img src={images.delete} alt={t("buttons.delete")} />
                </button>
            </td>}
        </tr>
    );
};

export default CartItem;
