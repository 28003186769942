import React, {useState} from 'react';
import ToolbarFileButton from './ToolbarFileButton';
import ImageThresholdModal from './ImageThresholdModal';

const ToolbarFileButtonThreshold = ({onChange}) => {
    const [isOpen, setOpen] = useState(false);
    const [threshold, setThreshold] = useState(50);
    const [image, setImage] = useState(null);
    
    function handleChangeFile(result) {
        setThreshold(50);
        setOpen(true);
        setImage(result);
    }

    function handleOk(result) {
        setOpen(false);
        onChange(result);
    }

    function toggle() {
        setOpen(!isOpen);
    }

    return (
        <>
            <ToolbarFileButton onChange={handleChangeFile} />
            <ImageThresholdModal isOpen={isOpen} toggle={toggle} onChange={handleOk} threshold={threshold} setThreshold={setThreshold} image={image} />
        </>
    );
};

export default ToolbarFileButtonThreshold;
