import React from 'react';
import {Formik, Form, Field} from 'formik';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

class CartCodeToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && this.props.cart !== prevProps.cart && this.props.cart) {
            this.props.setVisibility(false);
            this.props.history.push('/carrello');
        }
    }

    handleSubmit(values) {
        this.props.cartSetCurrent(values.code);
    }

    render() {
        const {t} = this.props;
        if (!this.props.visible) {
            return null;
        }
        return (
            <div className="cart-code-bar">
                <Formik initialValues={{code: ''}} onSubmit={this.handleSubmit}>
                    <Form className="form-inline ave-form">
                        <span className="ml-auto">{t('header.archiveCodeLabel')}</span>
                        <Field name="code" required className="form-control" placeholder={t('header.archiveCode')} />
                        <button type="submit" className="btn">&gt;</button>
                    </Form>
                </Formik>

                <form action="/catalogo" method="GET" className="form-inline ave-form mt-1">
                    <span className="ml-auto">{t('header.plateCodeLabel')}</span>
                    <input name="q" className="form-control" required placeholder={t('header.plateCode')}/>
                    <button type="submit" className="btn btn-primary">&gt;</button>
                </form>
            </div>
        );
    }
}

export default withRouter(withTranslation()(CartCodeToolbar));
