import React, {useState} from 'react';
import {Link, NavLink, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {cartSetCurrent} from '../actions/cartActions';
import CartCodeToolbar from './configurator/CartCodeToolbar';
import * as images from '../images';
import {useTranslation} from "react-i18next";

const Breadcrumbs = ({step}) => {
    const {t} = useTranslation();
    return (
        <ol className="header__breadcrumbs">
            <li className={classNames('active', {current: (step === 1)})}>
                <Link to='/ispirazioni'>{t("header.step1")}</Link>
            </li>
            <li className={classNames({active: (step >= 2), current: (step === 2)})}>
                {step >= 2 ? <Link to='/ispirazioni'>{t("header.step2")}</Link> : <span>{t("header.step2")}</span>}
            </li>
            <li className={classNames({active: (step >= 3), current: (step === 3)})}>
                <span>{t("header.step3")}</span>
            </li>
        </ol>
    );
};

const Header = ({cart, cartSetCurrent}) => {
    const [codeVisible, setCodeVisible] = useState(false);
    const {t, i18n} = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    return (
        <>
            <nav className="header navbar navbar-expand navbar-dark bg-primary">
                <Link className="navbar-brand" to="/"><img src={images.logo} alt={t("attributes.ave")} /></Link>
                <Route path="/ispirazioni"><Breadcrumbs step={1} /></Route>
                <Route path="/catalogo"><Breadcrumbs step={2} /></Route>
                <Route path="/configuratore"><Breadcrumbs step={3} /></Route>
                <ul className="navbar-nav lang-switcher ml-auto">
                    <li className="nav-item">
                        <button className="btn btn-link nav-link" onClick={() => changeLanguage("it")}>
                            <span className={classNames('lang ita', {active: i18n.language === "it"})}>ITA</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-link nav-link " onClick={() => changeLanguage("en")}>
                            <span className={classNames('lang en', {active: i18n.language === "en"})}>ENG</span>
                        </button>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <NavLink to="/carrello" className="nav-link">
                            {t("header.archive")} | {String(cart ? cart.items.length : 0).padStart(2, '0')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <span className={classNames('nav-link ml-3', {active: codeVisible})} onClick={() => setCodeVisible(!codeVisible)}>
                            ☰
                        </span>
                    </li>
                </ul>
            </nav>
            {<CartCodeToolbar visible={codeVisible} setVisibility={setCodeVisible} cartSetCurrent={cartSetCurrent} cart={cart} />}
        </>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart.current,
});

export default connect(mapStateToProps, {cartSetCurrent})(Header);
