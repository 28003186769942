import React from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {MINIMUM_ITEM_SIZE, STAGES, DRAG_TYPES} from '../../constants';
import WindowEditor from './WindowEditor';
import DroppableArea from './DroppableArea';
import {substageToAreaType} from '../../lib/plateUtils';
import SelectableArea from './SelectableArea';
import ItemMoveableArea from './ItemMoveableArea';

const getAreaId = (index) => `a_${index}`;
const getPlaceholderId = (index) => `ph_${index}`;
const getWindowPlaceholderId = ({col, window}, index) => `ph_w${window}_c${col}_${index}`;

class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this._canvas = React.createRef();
        this.state = {
            movingId: null,
            resizingId: null,
        };
        this.handleDrop = this.handleDrop.bind(this);
        this.handleResize = debounce(this.handleResize.bind(this), 50);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.triggerSettingsChange = throttle((newSettings) => this.props.onChange(newSettings), 50);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.forceUpdate();
    }

    handleDrop(item) {
        if (item.type === DRAG_TYPES.ITEM) {
            const index = this.props.settings.items.findIndex(({id}) => id === item.id);
            const items = this.props.settings.items.slice();
            items[index].area = item.area;
            items[index].areaId = item.areaId;
            items[index].pos = item.pos;
            items[index].size = item.size;
            this.props.onChange(Object.assign({}, this.props.settings, {items}));
        } else {
            this.props.onChange(Object.assign({}, this.props.settings, {items: this.props.settings.items.concat([item])}));
        }
        this.props.onSelect(item.id);
    }

    handleItemResizeStart(item, ratio, x, y, handle) {
        this._resizeData = {item: item.id, x, y, ratio, initialPos: item.pos.slice(), initialSize: item.size.slice(), naturalSize: item.naturalSize.slice(), handle};
        this.setState({resizingId: item.id});
    }

    handleItemStartMove(item) {
        this.setState({movingId: item.id});
    }
    handleItemEndMove() {
        this.setState({movingId: null});
    }

    handleItemDelete(item) {
        this.props.onChange(Object.assign({}, this.props.settings, {items: this.props.settings.items.filter(({id}) => id !== item.id)}));
    }

    handleItemResizeToFit(item) {
        const itemRatio = item.naturalSize[1] / item.naturalSize[0];
        const areaRatio = item.area[3] / item.area[2];
        const size = [0, 0];
        if (itemRatio >= areaRatio) {
            size[1] = item.area[3];
            size[0] = size[1] / itemRatio;
        } else {
            size[0] = item.area[2];
            size[1] = size[0] * itemRatio;
        }
        const x = item.area[0] + (item.area[2] - size[0]) / 2;
        const y = item.area[1] + (item.area[3] - size[1]) / 2;
        this.updateItem(item.id, {pos: [x, y], size});
    }
    handleItemHCenter(item) {
        const x = item.area[0] + (item.area[2] - item.size[0]) / 2;
        const y = item.pos[1];
        this.updateItem(item.id, {pos: [x, y]});
    }
    handleItemVCenter(item) {
        const x = item.pos[0];
        const y = item.area[1] + (item.area[3] - item.size[1]) / 2;
        this.updateItem(item.id, {pos: [x, y]});
    }

    updateItem(itemId, newValues) {
        const index = this.props.settings.items.findIndex(({id}) => id === itemId);
        if (index < 0) {
            throw new Error(`Cannot find item with ID ${itemId}`);
        }
        const items = this.props.settings.items.slice();
        Object.assign(items[index], newValues);
        this.props.onChange(Object.assign({}, this.props.settings, {items}));
    }

    move(screenX, screenY) {
        if (this._resizeData) {
            const itemIndex = this.props.settings.items.findIndex(({id}) => this._resizeData.item === id);
            if (itemIndex < 0) {
                delete this._resizeData;
                return;
            }
            const itemRatio = this._resizeData.naturalSize[1] / this._resizeData.naturalSize[0];
            const items = this.props.settings.items.slice();
            let deltaX = screenX - this._resizeData.x;
            let deltaY = screenY - this._resizeData.y;
            if (this._resizeData.handle.includes('n')) {
                deltaY *= -1;
            }
            if (this._resizeData.handle.includes('w')) {
                deltaX *= -1;
            }
            let newWidth, newHeight;
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                newWidth = this._resizeData.initialSize[0] + deltaX / this._resizeData.ratio;
                newHeight = newWidth * itemRatio;
            } else {
                newHeight = this._resizeData.initialSize[1] + deltaY / this._resizeData.ratio;
                newWidth = newHeight / itemRatio;
            }
            newWidth = Math.max(newWidth, MINIMUM_ITEM_SIZE);
            newHeight = Math.max(newHeight, MINIMUM_ITEM_SIZE);
            const pos = this._resizeData.initialPos.slice();
            const size = [newWidth, newHeight];
            if (this._resizeData.handle.includes('n')) {
                pos[1] -= newHeight - this._resizeData.initialSize[1];
            }
            if (this._resizeData.handle.includes('w')) {
                pos[0] -= newWidth - this._resizeData.initialSize[0];
            }
            items[itemIndex] = Object.assign({}, items[itemIndex], {pos, size});
            this.triggerSettingsChange(Object.assign({}, this.props.settings, {items}));
        }
    }

    /**
     * @param {MouseEvent} e
     */
    handleMouseMove(e) {
        if (this._resizeData && e.button !== 0) {
            delete this._resizeData;
            return;
        }
        this.move(e.screenX, e.screenY);
    }

    /**
     * @param {TouchEvent} e
     */
    handleTouchMove(e) {
        this.move(e.changedTouches[0].screenX, e.changedTouches[0].screenY);
    }

    deleteResizeData() {
        this.setState({resizingId: null});
        delete this._resizeData;
    }

    /**
     * @param {MouseEvent} e
     */
    handleMouseUp(e) {
        this.deleteResizeData()
    }

    /**
     * @param {TouchEvent} e
     */
    handleTouchEnd(e) {
        this.deleteResizeData()
    }

    renderToCanvas() {
        if (!this._canvas.current) {
            return;
        }

        // Update the canvas display size to equal the style size, see https://stackoverflow.com/a/4939066/2564990
        const {width, height} = this._canvas.current.getBoundingClientRect();
        this._canvas.current.width = width;
        this._canvas.current.height = height;
        return this.props.renderer.render(this._canvas.current, this.props.plate, {hiddenContents: [this.state.movingId].filter((a) => a)});
    }

    renderChildren({origin, ratio}) {
        const {configBuilder, stage, substage, onChange, onSelect, selectedId, settings} = this.props;
        const results = [];
        switch (stage) {
            case STAGES.WINDOWS:
                results.push(...configBuilder.finestre.map(({area, ...rest}, i) => (
                    <WindowEditor key={i} {...rest} window={i}
                        onChange={onChange} ratio={ratio}
                        jacks={settings.jacks} availableJacks={configBuilder.frutti} configBuilder={configBuilder}
                        style={{position: 'absolute', left: area[0] * ratio, top: area[1] * ratio, width: area[2] * ratio, height: area[3] * ratio}} />
                )));
                break;
            case STAGES.CONTENT:
                results.push(...configBuilder.areas.filter(({types}) => types.includes(substageToAreaType(substage))).map(({area}, i) => (
                    <DroppableArea key={getAreaId(i)} id={getAreaId(i)}
                        onDrop={this.handleDrop} area={area} type={substageToAreaType(substage)} ratio={ratio}
                        style={{position: 'absolute', left: area[0] * ratio, top: area[1] * ratio, width: area[2] * ratio, height: area[3] * ratio}} />
                )));
                const jacks = settings.jacks.map((jackSettings) => {
                    const jack = configBuilder.frutti.find(({code}) => code === jackSettings.code);
                    const window = configBuilder.finestre[jackSettings.window];
                    return {jackSettings, jack, window};
                })
                results.push(...configBuilder.placeholders.filter(({types}) => types.includes(substageToAreaType(substage))).map(({area}, i) => (
                    <DroppableArea key={getPlaceholderId(i)} id={getPlaceholderId(i)}
                        onDrop={this.handleDrop}
                        area={area}
                        type={substageToAreaType(substage)}
                        ratio={ratio}
                        snap
                        style={{position: 'absolute', left: area[0] * ratio, top: area[1] * ratio, width: area[2] * ratio, height: area[3] * ratio}} />
                )));
                for (const {jack, jackSettings, window} of jacks) {
                    results.push(...jack.placeholders.filter(({types}) => types.includes(substageToAreaType(substage))).map(({area}, i) => (
                        <DroppableArea key={getWindowPlaceholderId(jackSettings, i)} id={getWindowPlaceholderId(jackSettings, i)}
                            onDrop={this.handleDrop}
                            area={[
                                window.area[0] + window.area[2] / window.columns * jackSettings.col + area[0],
                                window.area[1] + area[1],
                                area[2], area[3],
                            ]}
                            type={substageToAreaType(substage)}
                            ratio={ratio}
                            snap
                            style={{position: 'absolute', 
                                left: (window.area[0] + window.area[2] / window.columns * jackSettings.col + area[0]) * ratio,
                                top: (window.area[1] + area[1]) * ratio,
                                width: area[2] * ratio,
                                height: area[3] * ratio}} />
                    )));
                }
                results.push(settings.items
                    .filter(({areaType}) => areaType === substageToAreaType(substage))
                    .map((item) => (
                        <ItemMoveableArea key={item.id}
                            item={item} ratio={ratio}
                            style={{
                                left: (item.pos[0]) * ratio, top: (item.pos[1]) * ratio,
                                width: item.size[0] * ratio, height: item.size[1] * ratio,
                            }}
                            hide={!!this.state.movingId}
                            disabled={!!this.state.resizingId}
                            onStart={() => this.handleItemStartMove(item)} onEnd={() => this.handleItemEndMove(item)}>
                            <SelectableArea
                                selected={selectedId === item.id}
                                onMouseDown={(e) => {onSelect(item.id); e.stopPropagation()}}
                                onClick={(e) => {onSelect(item.id); e.stopPropagation()}}
                                size={item.size}
                                ratio={ratio}
                                onResizeStart={(...args) => this.handleItemResizeStart(item, ratio, ...args)}
                                onDelete={() => this.handleItemDelete(item)}
                                onResizeToFit={() => this.handleItemResizeToFit(item)}
                                onHCenter={() => this.handleItemHCenter(item)}
                                onVCenter={() => this.handleItemVCenter(item)}
                            />
                        </ItemMoveableArea>
                    )));
                break;
            default:
                break;
        }

        return (
            <div style={{left: origin[0], top: origin[1], position: 'absolute'}}>{results}</div>
        );
    }

    render() {
        const {configBuilder, stage, height, onChange, plate, settings, selectedId, onSelect, renderer, ...rest} = this.props;
        const result = this.renderToCanvas();
        return (
            <div style={{width: '100%', height, position: 'relative'}}
                onMouseMove={this.handleMouseMove}
                onTouchMove={this.handleTouchMove}
                onMouseUp={this.handleMouseUp}
                onTouchEnd={this.handleMouseUp}
                onClick={() => onSelect(null)}>
                <canvas ref={this._canvas} className="cfg__canvas" {...rest} />
                {result && this.renderChildren(result)}
            </div>
        );
    }
}

export default Canvas;
