import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from '../actions/catalogActions';
import * as api from '../api';
import * as constants from '../constants';

function* catalogGetItem({payload}) {
    try {
        const result = yield call(api.catalogGetPlate, payload.code);
        yield put(actions.catalogGetItemSuccess(result));
    } catch (err) {
        yield put(actions.catalogGetItemError(err));
    }
}

function* catalogGetList() {
    try {
        const result = yield call(api.catalogGetPlateList);
        yield put(actions.catalogGetListSuccess(result));
    } catch (err) {
        yield put(actions.catalogGetListError(err));
    }
}

export default function* cartSagas() {
    yield all([
        takeLatest(constants.CATALOG_GET_ITEM, catalogGetItem),
        takeLatest(constants.CATALOG_GET_LIST, catalogGetList),
    ]);
}
