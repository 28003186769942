import React from 'react';
import {connect} from 'react-redux';
import {cartPlaceOrder} from '../actions/cartActions';
import PlaceOrderConfirm from './cart/PlaceOrderConfirm';
import OrderCompleted from './cart/OrderCompleted';

class PlaceOrder extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        this.props.cartPlaceOrder(values);
    }

    render() {
        const {cart} = this.props;
        return (
            <div className="container mb-5">
                {!cart ? (
                    <OrderCompleted />
                ) : (
                    <PlaceOrderConfirm onSubmit={this.handleSubmit} />
                )}                
            </div>
        );
    }
}

export default connect(undefined, {cartPlaceOrder})(PlaceOrder);
