import React from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import * as images from '../../images';

const SelectableArea = ({selected, size, ratio, onHCenter, onVCenter, onResizeToFit, onDelete, onResizeStart, ...rest}) => {
    const {t} = useTranslation();
    const handleMouseDown = (e, handle) => {
        if (e.button === 0) {
            e.preventDefault();
            onResizeStart(e.screenX, e.screenY, handle);
        }
    };
    const handleTouchStart = (e, handle) => {
        e.preventDefault();
        onResizeStart(e.changedTouches[0].screenX, e.changedTouches[0].screenY, handle);
    };
    const handleTouchEnd = (e) => {
        e.preventDefault();
    };
    return (
        <div className={classNames("cfg__draggable-area_selectable-area", {selected})}
            {...rest}>
    
            {selected && <>
                <div className="cfg__draggable-area_toolbar">
                    <button className="button" onClick={onHCenter} title={t("buttons.centerH")}><img src={images.hcenter} alt="H" /></button>
                    <button className="button" onClick={onVCenter} title={t("buttons.centerV")}><img src={images.vcenter} alt="V" /></button>
                    <button className="button" onClick={onResizeToFit} title={t("buttons.fit")}><img src={images.fit} alt="R" /></button>
                    <button className="button" onClick={onDelete} title={t("buttons.delete")}><img src={images.delete} alt="D" /></button>
                </div>
                <button
                    className="cfg__draggable-area_resize-handle handle handle-ne"
                    onMouseDown={(e) => handleMouseDown(e, 'ne')}
                    onTouchStart={(e) => handleTouchStart(e, 'ne')}
                    onTouchEnd={(e) => handleTouchEnd(e)}
                />
                <button
                    className="cfg__draggable-area_resize-handle handle handle-se"
                    onMouseDown={(e) => handleMouseDown(e, 'se')}
                    onTouchStart={(e) => handleTouchStart(e, 'se')}
                    onTouchEnd={(e) => handleTouchEnd(e)}
                />
                <button
                    className="cfg__draggable-area_resize-handle handle handle-nw"
                    onMouseDown={(e) => handleMouseDown(e, 'nw')}
                    onTouchStart={(e) => handleTouchStart(e, 'nw')}
                    onTouchEnd={(e) => handleTouchEnd(e)}
                />
                <button
                    className="cfg__draggable-area_resize-handle handle handle-sw"
                    onMouseDown={(e) => handleMouseDown(e, 'sw')}
                    onTouchStart={(e) => handleTouchStart(e, 'sw')}
                    onTouchEnd={(e) => handleTouchEnd(e)}
                />
            </>}
        </div>
    );    
};

export default SelectableArea;
