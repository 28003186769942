import React from 'react';
import {DragPreviewImage, useDrag} from 'react-dnd';

const DraggableItem = ({item, preview: previewImage, ...rest}) => {
    const [{isDragging}, drag, preview] = useDrag({
        item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    return (
        <>
            {previewImage && <DragPreviewImage connect={preview} src={previewImage} />}
            <button ref={drag} style={{opacity: isDragging ? 0.5 : 1, cursor: 'move'}} {...rest} />
        </>
    );
};

export default DraggableItem;
