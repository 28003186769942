import React from 'react';
import {Provider} from 'react-redux'
import {DndProvider} from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import Root from './components/Root';
import store from './store';

const App = () => (
    <Provider store={store}>
        <DndProvider options={HTML5toTouch}>
            <Root />
        </DndProvider>
    </Provider>
);

export default App;
