import React from 'react';
import {useTranslation} from "react-i18next";

const OrderCompleted = () => {
    const {t} = useTranslation();
    return (
        <div className="mt-5 text-center">
            <div className="alert alert-success" role="alert">
                {t('messages.orderSent')}
            </div>

            <p>
                {t('cart.istruzioni1')}<br/>
                {t('cart.istruzioni2')}<br/>
                {t('cart.istruzioni3')}<br/>
                {t('cart.istruzioni4')}<br/>
                {t('cart.istruzioni5')}<br/>
                {t('cart.istruzioni6')}<br/><br/>
                {t('cart.istruzioni7')}
            </p>

            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="alert alert-success" role="alert">
                        {t('messages.emailinstructions')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderCompleted;
