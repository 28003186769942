import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {cartSave} from '../actions/cartActions';
import SaveCartForm from './cart/SaveCartForm';

class SaveCart extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {saved: false};
    }

    handleSubmit(values) {
        this.props.cartSave(this.props.cart, values);
        this.setState({saved: true});
    }

    render() {
        const {cart, t} = this.props;
        return (
            <div className="container mb-5">
                <div className="mt-5 text-center">
                    <div className="alert alert-info" role="alert">
                        {t('cart.salvato')}
                    </div>
                </div>
                {this.state.saved ? (
                    <div className="mt-5 text-center">
                        <p>
                            {t('cart.istruzioni1')}<br/>
                            {t('cart.istruzioni2')}<br/>
                            {t('cart.istruzioni3')}<br/>
                            {t('cart.istruzioni4')}<br/>
                            {t('cart.istruzioni5')}<br/>
                            {t('cart.istruzioni6')}<br/><br/>
                            {t('cart.istruzioni7')}
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="mt-5 text-center">
                            <p>{t('cart.salvatoCodice')}</p>
                            <span><strong>{cart.code}</strong></span>
                        </div>
                        <hr />
                        <div className="mt-5 row justify-content-center">
                            <div className="col-8">
                                <p>{t('cart.compila')}</p>
                                <SaveCartForm onSubmit={this.handleSubmit} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    saved: state.cart.saved,
    saving: state.cart.saving,
})

export default connect(mapStateToProps, {cartSave})(withTranslation()(SaveCart));
