import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {withTranslation} from "react-i18next";
import throttle from 'lodash/throttle';
import {generateThresholdImage, createCroppedCanvas} from '../../../lib/utils';

const PREVIEW_MAX_SIZE = 240;
const PREVIEW_THROTTLE_MS = 40;
const LASER_COLOR = [0, 0, 0];

class ImageThresholdModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.state = {imageReady: false, outputImage: null};
        this._canvas = document.createElement('canvas');
        this._img = null;
        this._imageData = null;

        this.generatePreviewThrottled = throttle(this.generatePreview.bind(this), PREVIEW_THROTTLE_MS);
    }

    handleOk() {
        const fullSizedCanvas = document.createElement('canvas');
        fullSizedCanvas.width = this._img.naturalWidth;
        fullSizedCanvas.height = this._img.naturalHeight;
        const ctx = fullSizedCanvas.getContext('2d');
        ctx.clearRect(0, 0, fullSizedCanvas.width, this._canvas.height);
        ctx.drawImage(this._img, 0, 0);
        const fullSizedImageData = ctx.getImageData(0, 0, fullSizedCanvas.width, fullSizedCanvas.height);

        generateThresholdImage(fullSizedImageData, this.props.threshold / 100, fullSizedCanvas, LASER_COLOR);

        const croppedCanvas = createCroppedCanvas(fullSizedCanvas);

        this.props.onChange(croppedCanvas.toDataURL());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.image !== prevProps.image) {
            if (this._img) {
                this._img.onload = null;
                this._img = null;
            }
            this._imageData = null;
            this.setState({imageReady: false});
            this._img = document.createElement('img');
            this._img.onload = () => {
                this._canvas.width = this._img.naturalWidth;
                this._canvas.height = this._img.naturalHeight;
                const longestSize = Math.max(this._img.naturalWidth, this._img.naturalHeight);
                if (longestSize > PREVIEW_MAX_SIZE) {
                    const ratio = PREVIEW_MAX_SIZE / longestSize;
                    this._canvas.width = this._img.naturalWidth * ratio;
                    this._canvas.height = this._img.naturalHeight * ratio;
                }
                this.setState({imageReady: true});
                this.generatePreview();
            };
            this._img.src = this.props.image;
        }
        if (this.props.threshold !== prevProps.threshold) {
            this.generatePreviewThrottled();
        }
    }

    generatePreview() {
        if (!this._imageData) {
            const ctx = this._canvas.getContext('2d');
            ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
            ctx.drawImage(this._img, 0, 0, this._img.naturalWidth, this._img.naturalHeight, 0, 0, this._canvas.width, this._canvas.height);
            this._imageData = ctx.getImageData(0, 0, this._canvas.width, this._canvas.height);
        }

        generateThresholdImage(this._imageData, this.props.threshold / 100, this._canvas, LASER_COLOR);

        this.setState({outputImage: this._canvas.toDataURL()});
    }

    render() {
        if (!this.state.imageReady) {
            return null;
        }
        const {isOpen, threshold, setThreshold, toggle, t} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('instructions.prepara')}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-6 text-center">
                            <p>{t('attributes.originale')}</p>
                            <img className="img-fluid mb-4 border" src={this.props.image} alt={t('attributes.originale')} />
                        </div>
                        <div className="col-6 text-center">
                            <p>{t('attributes.elaborata')}</p>
                            <img className="img-fluid mb-4 border" src={this.state.outputImage} alt={t('attributes.elaborata')} />
                        </div>
                    </div>
                    <label htmlFor="toolbar-file-button-threshold-modal__threshold">{t('attributes.soglia')}:</label>
                    <input type="range" className="custom-range" id="toolbar-file-button-threshold-modal__threshold"
                        min="0" max="100" step="1" value={threshold} onChange={(e) => setThreshold(+e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>{t('buttons.annulla')}</Button>
                    {' '}
                    <Button color="primary" onClick={this.handleOk}>{t('buttons.ok')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withTranslation()(ImageThresholdModal);
