import * as constants from '../constants';

export const cartGetCurrent = () => ({type: constants.CART_GET_CURRENT});
export const cartGetCurrentSuccess = (item) => ({type: constants.CART_GET_CURRENT_SUCCESS, payload: {item}});
export const cartGetCurrentError = (error) => ({type: constants.CART_GET_CURRENT_ERROR, payload: {error}});

export const cartSetCurrent = (code) => ({type: constants.CART_SET_CURRENT, payload: {code}});
export const cartSetCurrentSuccess = (item) => ({type: constants.CART_SET_CURRENT_SUCCESS, payload: {item}});
export const cartSetCurrentError = (error) => ({type: constants.CART_SET_CURRENT_ERROR, payload: {error}});

export const cartAdd = (plate, item, thumbnail, quantity, cartItemId) => ({type: constants.CART_ADD, payload: {item, quantity, plate, thumbnail, cartItemId}});
export const cartAddProgress = (progress) => ({type: constants.CART_ADD_PROGRESS, payload: {progress}});
export const cartAddSuccess = (item) => ({type: constants.CART_ADD_SUCCESS, payload: {item}});
export const cartAddError = (error) => ({type: constants.CART_ADD_ERROR, payload: {error}});

export const cartUpdateItem = (itemId, values) => ({type: constants.CART_UPDATE_ITEM, payload: {itemId, values}});
export const cartDeleteItem = (itemId, values) => ({type: constants.CART_DELETE_ITEM, payload: {itemId}});
export const cartUpdateSuccess = (item) => ({type: constants.CART_UPDATE_SUCCESS, payload: {item}});
export const cartUpdateError = (error) => ({type: constants.CART_UPDATE_ERROR, payload: {error}});

export const cartGetItem = (itemId) => ({type: constants.CART_GET_ITEM, payload: {itemId}});
export const cartGetItemSuccess = (item) => ({type: constants.CART_GET_ITEM_SUCCESS, payload: {item}});
export const cartGetItemError = (error) => ({type: constants.CART_GET_ITEM_ERROR, payload: {error}});

export const cartPlaceOrder = (values) => ({type: constants.CART_PLACE_ORDER, payload: {values}});
export const cartPlaceOrderSuccess = (item) => ({type: constants.CART_PLACE_ORDER_SUCCESS, payload: {item}});
export const cartPlaceOrderError = (error) => ({type: constants.CART_PLACE_ORDER_ERROR, payload: {error}});

export const cartSave = (cart, values) => ({type: constants.CART_SAVE, payload: {cart, values}});
export const cartSaveSuccess = () => ({type: constants.CART_SAVE_SUCCESS});
export const cartSaveError = (error) => ({type: constants.CART_SAVE_ERROR, payload: {error}});

export const cartNew = ({clone} = {}) => ({type: constants.CART_NEW, payload: {clone: !!clone}});
export const cartNewSuccess = (item) => ({type: constants.CART_NEW_SUCCESS, payload: {item}});
export const cartNewError = (error) => ({type: constants.CART_NEW_ERROR, payload: {error}});
