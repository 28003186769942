import React from 'react';
import {DragPreviewImage, useDrag} from 'react-dnd';
import {DRAG_TYPES, TRANSPARENT_IMAGE} from '../../constants';

const ItemMoveableArea = ({children, disabled, hide, item, ratio, style, onStart, onEnd}) => {
    const [, drag, preview] = useDrag({
        begin: () => {setTimeout(onStart, 0)},
        end: () => {onEnd()},
        isDragging: (monitor) => {
            return monitor.getItem().id === item.id;
        },
        canDrag: () => !disabled,
        item: {
            dpmm: item.dpmm,
            id: item.id,
            size: [item.size[0] * ratio * item.dpmm, item.size[1] * ratio * item.dpmm],
            src: item.src,
            type: DRAG_TYPES.ITEM,
        },
    });
    return (
        <>
            {<DragPreviewImage connect={preview} src={TRANSPARENT_IMAGE} />}
            {!hide && (
                <div className="cfg__draggable-area" ref={drag} style={style}>{children}</div>
            )}
        </>
    );
};

export default ItemMoveableArea;
