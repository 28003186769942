import React from 'react';
import {withTranslation} from "react-i18next";
import JackPicker from './JackPicker';
import ToolbarButton from './ToolbarButton';
import ToolbarSeparator from './ToolbarSeparator';
import Toolbar from './Toolbar';

class JackTools extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onChange({jacks: []});
    }

    render() {
        const {items, t} = this.props;
        return (
            <Toolbar>
                <JackPicker className="col-auto" items={items} />
                <ToolbarSeparator />
                <div className="col-auto">
                    <ToolbarButton onClick={this.handleClick}>
                        <ToolbarButton.Image image="delete" />
                        <ToolbarButton.Label>{t("buttons.deleteAll")}</ToolbarButton.Label>
                    </ToolbarButton>
                </div>
            </Toolbar>
        );
    }
}

export default withTranslation()(JackTools);
