import React from 'react';
import classNames from 'classnames';
import {STAGES} from '../../../constants';
import {withTranslation} from 'react-i18next';
import StageLink from './StageLink';
import {hasStage} from '../../../lib/plateUtils';
import * as images from '../../../images';

const STAGES_CONFIG = [
    {id: STAGES.COLORS, label: 'sceltaColore', img: images.paintBucket},
    {id: STAGES.LAYOUTS, label: 'sceltaLayout', img: images.frame},
    {id: STAGES.ACCESSORIES, label: 'sceltaAccessori', img: images.frame},
    {id: STAGES.WINDOWS, label: 'posizionamentoComandi', img: images.compos},
    {id: STAGES.CONTENT, label: 'posizionamentoLibero', img: images.custom},
    {id: STAGES.CHECKOUT, label: 'anteprima', img: images.prevImage},
];

const getAvailableStages = (configBuilder) => STAGES_CONFIG.filter(({id}) => hasStage(configBuilder, id)).map(({id}) => id);

class StageNavBar extends React.Component {
    render() {
        const {configBuilder, onChangeStage, stage, t} = this.props;
        const availableStages = getAvailableStages(configBuilder);
        const currentStage = availableStages.indexOf(stage);
        const percentage = currentStage / (availableStages.length - 1) * 100;
        return (
            <div className="stage-nav-bar">
                <div className="stage-nav-bar__plate">
                    <h3>{t("plate.modello")}</h3>
                    <div className="model">{configBuilder.code}</div>
                    {t("plate.serie")} {t(`dictionary.series.${configBuilder.serie}`)}
                    {` | `}
                    {t(`dictionary.dimensions.${configBuilder.moduli}`)}
                </div>
                <div className="stage-nav-bar__container">
                    <div className="stage-nav-bar__progressbar">
                        <div style={{width: `${percentage.toPrecision(4)}%`}} />
                    </div>
                    {STAGES_CONFIG.filter(({id}) => hasStage(configBuilder, id)).map(({id, label, img}, index) => (
                        <StageLink stage={id} active={stage === id} onClick={onChangeStage} key={id}>
                            <img src={img} alt={t(`stages.${label}`)} />
                            <span><strong>3.{index + 1}</strong><br /> {t(`stages.${label}`)}</span>
                        </StageLink>
                    ))}
                </div>
                <div className="stage-nav-bar__details">
                    <h3>{t("plate.comando")}</h3>
                    <div className="items">
                        <span className={classNames('item', {active: configBuilder.tipologia === "FINESTRA"})}>{t("dictionary.types.FINESTRA")}</span> |
                        <span className={classNames('item', {active: configBuilder.tipologia === "LEVETTA"})}>{t("dictionary.types.LEVETTA")}</span> |
                        <span className={classNames('item', {active: configBuilder.tipologia === "PRESETONDE"})}>{t("dictionary.types.PRESETONDE")}</span> |
                        <span className={classNames('item', {active: configBuilder.tipologia === "TOUCH"})}>{t("dictionary.types.TOUCH")}</span>
                    </div>
                    <h3>{t("plate.materiale")}</h3>
                    <div className="items">
                        <span className={classNames('item', {active: configBuilder.materiale === "ALLUMINIO"})}>{t("dictionary.materials.ALLUMINIO")}</span> |
                        <span className={classNames('item', {active: configBuilder.materiale === "CORIAN"})}>{t("dictionary.materials.CORIAN")}</span> |
                        <span className={classNames('item', {active: configBuilder.materiale === "TECNOPOLIMERO"})}>{t("dictionary.materials.TECNOPOLIMERO")}</span> |
                        <span className={classNames('item', {active: configBuilder.materiale === "VETRO"})}>{t("dictionary.materials.VETRO")}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(StageNavBar);
