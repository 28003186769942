import * as utils from './utils';
import {CONTENT_TYPES} from '../constants';
import {assetUrl} from '../config';
import invertImage from './invertImage';

export default class Renderer {
    constructor(imageCache) {
        this._imageCache = imageCache;
    }

    /**
     * @private
     * @param {CanvasRenderingContext2D} ctx
     * @param {object} layer
     * @param {object} options
     */
    _renderLayer(ctx, layer, {config, hiddenContents, ratio}) {
        if (layer.image) {
            const img = this._imageCache.get(assetUrl(layer.image));
            ctx.save();
            if (layer.compositeOperation) {
                ctx.globalCompositeOperation = layer.compositeOperation;
            }
            if (layer.color) {
                ctx.drawImage(utils.multiplyImage(img, layer.color), 0, 0, config.width * ratio, config.height * ratio);
            } else {
                ctx.drawImage(img, 0, 0, config.width * ratio, config.height * ratio);
            }
            ctx.restore();
        }

        if (layer.content) {
            for (const content of layer.content) {
                if (hiddenContents.includes(content.id)) {
                    continue;
                }
                ctx.save();
                if (content.clip) {
                    ctx.beginPath();
                    ctx.rect(content.clip[0] * ratio, content.clip[1] * ratio, content.clip[2] * ratio, content.clip[3] * ratio);
                    ctx.clip();
                }
                switch (content.type) {
                    case CONTENT_TYPES.IMAGE:
                        let image = this._imageCache.get(content.src);
                        if (layer.type === 'laser' && config.dark) {
                            image = invertImage(image, 1);
                        }
                        if (image) {
                            ctx.drawImage(image,
                                content.pos[0] * ratio, content.pos[1] * ratio, content.size[0] * ratio, content.size[1] * ratio);
                        }
                        break;
                    case CONTENT_TYPES.TEXT:
                        ctx.textBaseline = 'top';
                        ctx.fillStyle = '#ffffff'; // TODO: pick color from `content` or from default laser color
                        ctx.font = `${content.size * ratio}px sans-serif`; // TODO: pick font from `content`
                        ctx.fillText(content.text, content.pos[0] * ratio, content.pos[1] * ratio);
                        break;
                    default:
                        console.error(`Unknown content type "${content.type}"`);
                }
                ctx.restore();
            }
        }
    }

    /**
     * @param {HTMLCanvasElement} canvas The canvas element
     * @param {object} config The configuration object
     */
    render(canvas, config, {hiddenContents}) {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const ratio = Math.min(canvas.width / config.width, canvas.height / config.height);

        const plateWidth = config.width * ratio;
        const origin = [(canvas.width - plateWidth) / 2, 0];
        ctx.translate(origin[0], origin[1]);

        for (const layer of config.layers) {
            this._renderLayer(ctx, layer, {config, hiddenContents, ratio});
        }

        return {origin, ratio};
    }
}
