import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';
import {cartGetCurrent} from '../actions/cartActions';
import ConfiguratorWrapper from './ConfiguratorWrapper';
import CartRoutes from './CartRoutes';
import Catalog from './Catalog';
import Cookie from './Cookie';
import Contacts from './Contacts';
import Inspiration from './Inspiration';
import Privacy from './Privacy';
import Intro from './Intro';
import Landing44PY from './Landing44PY';
import Layout from './Layout';

class Root extends React.Component {
    componentDidMount() {
        if (!this.props.cart) {
            this.props.cartGetCurrent();
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={Intro} />
                    <Route>
                        <Layout>
                            <Switch>
                                <Route path="/catalogo" component={Catalog} />
                                <Route path="/carrello" component={CartRoutes} />
                                <Route path="/cookie" component={Cookie} />
                                <Route path="/configuratore/:code/clona/:cartItemId" component={ConfiguratorWrapper} />
                                <Route path="/configuratore/:code/modifica/:cartItemId" component={ConfiguratorWrapper} />
                                <Route path="/configuratore/:code" component={ConfiguratorWrapper} />
                                <Route path="/contatti" component={Contacts} />
                                <Route path="/ispirazioni" component={Inspiration} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/44PY" component={Landing44PY} />
                                <Route path="/" component={Intro} />
                            </Switch>
                        </Layout>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default connect(undefined, {cartGetCurrent})(Root);
