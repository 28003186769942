import React from 'react';
import {withTranslation} from "react-i18next";
import {hasLowResImage} from '../../../lib/plateUtils';
import {CONTEXTS} from '../../../constants';

const PreviewTools = ({settings, context, onChangeNotes, t}) => (
    <>
        <div className="label text-center">
            {context === CONTEXTS.PLATE_EDITING ?
                <><strong>{t("instructions.congrats")}!</strong> {t("instructions.modified")}</> :
                <><strong>{t("instructions.congrats")}!</strong> {t("instructions.completed")}</>
            }
            <br />
            {hasLowResImage(settings) && <p className="text-primary">
                <strong>{t("instructions.warning")}</strong>: {t("instructions.lowRes")}.
            </p>}
        </div>
        <div className="label text-center col-md-4 offset-md-4">
            <p>{t("instructions.note")}</p>
            <textarea value={settings.note} name="note" className="form-control" placeholder={t("instructions.notePlaceholder")} onChange={(e) => onChangeNotes(e.target.value)} />
        </div>
    </>
);

export default withTranslation()(PreviewTools);
