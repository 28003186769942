import React from 'react';
import {useTranslation} from "react-i18next";
import ContactsExport from './ContactsExport';
import ContactsOffices from './ContactsOffices';
import ContactsTechnicalinfo from './ContactsTechnicalinfo';

const Contacts = () => {
    const {t} = useTranslation();
    return (
        <div className="container">
            <h1 className="text-center mt-5 mb-4">{t("menu.contacts")}</h1>

            <div className="row text-center">
                <div className="col">
                    <p><strong><span className="testo5">AVE S.p.A.</span></strong><br />
                    Via Mazzini, 75 - 25086 Rezzato (Brescia), Italy<br />
                    Ph.+39 030 24981<br />
                    Fax +39 030 2792605</p>
                </div>
                <ContactsTechnicalinfo />
            </div>
            <div className="row text-center">
                <ContactsOffices />
                <ContactsExport />
            </div>
            <iframe title="map" frameBorder="0" height="340" marginHeight="0" marginWidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2170.835705979342!2d10.301217595680269!3d45.51451868951013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47819e9e054a0ccb%3A0xe1bb322dc06bdb62!2sAve+Spa!5e0!3m2!1sit!2sit!4v1441005352458" width="98%"></iframe>
        </div>
    );
};

export default Contacts;
