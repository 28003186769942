import React from 'react';
import {connect} from 'react-redux';
import {cartAdd, cartGetItem} from '../actions/cartActions';
import {catalogGetItem} from '../actions/catalogActions';
import Configurator from './configurator/Configurator';

class ConfiguratorWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    handleAddToCart(item, thumbnail) {
        if (this.props.match.params.cartItemId && this.props.location.pathname.includes('modifica')) {
            this.props.cartAdd(this.props.match.params.code, item, thumbnail, null, this.props.match.params.cartItemId);
        } else {
            this.props.cartAdd(this.props.match.params.code, item, thumbnail, 1, null);
        }
    }

    componentDidMount() {
        if (!this.props.plate) {
            this.props.catalogGetItem(this.props.match.params.code);
        }
        if (this.props.match.params.cartItemId) {
            this.props.cartGetItem(this.props.match.params.cartItemId);
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.submitting && !this.props.submitting && !this.props.error) {
            this.props.history.push('/carrello');
        }
        return null;
    }

    componentDidUpdate() {
        // this.props.history.push('/carrello'); called in getSnapshotBeforeUpdate to avoid rendering children <PageLeaveWarning> component
    }

    render() {
        const {plate, item, submitting} = this.props;
        let settings;
        if (!plate) {
            return null; // TODO: loader
        }
        if (this.props.match.params.cartItemId) {
            if (item && item.id.toString() === this.props.match.params.cartItemId) {
                settings = item.data;
            } else {
                return null; // TODO: loader
            }
        }
        return (
            <Configurator plate={plate} key={plate} canvasHeight={300} onSubmit={this.handleAddToCart} settings={settings} location={this.props.location}
                submitting={submitting} />
        );
    }
}

const mapStateToProps = (state, props) => ({
    error: state.cart.error,
    submitting: state.cart.submitting,
    item: state.cart.item,
    plate: state.catalog.plates[props.match.params.code],
});

export default connect(mapStateToProps, {cartAdd, cartGetItem, catalogGetItem})(ConfiguratorWrapper);
