import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {VERSION_LABEL} from '../config';

const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer className="navbar navbar-expand navbar-dark">
            <span>
                <strong>Ave S.p.a.</strong> - Via Mazzini 75 - 25086 Rezzato (BS) - <span className="d-none d-lg-inline">Tel +39 030 24981 - Capitale versato: € 3.093.827,00 - R.I. Brescia e C.F. 00283500171 - R.E.A. Brescia 32356 - </span>P.IVA 00549940989
                - <span>{VERSION_LABEL}</span>
            </span>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <NavLink to="/privacy" className="nav-link">
                        <b>{t("menu.privacy")}</b>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/cookie" className="nav-link">
                        <b>{t("menu.cookie")}</b>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/contatti" className="nav-link">
                        <b>{t("menu.contacts")}</b>
                    </NavLink>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
