/**
 * @param {HTMLImageElement} img The image
 * @param {Number} amount The amount from 0 to 1
 */
const invertImage = (img, amount) => {
    if (!img.width || !img.height) {
        return;
    }
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
    const { data } = imageData;
    const { length } = data;

    // in rgba world, every
    // n * 4 + 0 is red,
    // n * 4 + 1 green and
    // n * 4 + 2 is blue
    // the fourth can be skipped as it's the alpha channel
    for (let i = 0; i < length; i += 4) {
        data[i + 0] = Math.abs(data[i + 0] - 255 * amount);
        data[i + 1] = Math.abs(data[i + 1] - 255 * amount);
        data[i + 2] = Math.abs(data[i + 2] - 255 * amount);
    }

    ctx.putImageData(imageData, 0, 0);
    return canvas;
};

export default invertImage;
