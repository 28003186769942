import React from 'react';
import {STAGES} from '../../../constants';
import AccessoryPicker from './AccessoryPicker';
import JackTools from './JackTools';
import LayoutPicker from './LayoutPicker';
import MaterialPicker from './MaterialPicker';
import ContentTools from './ContentTools';
import PreviewTools from './PreviewTools';

const StageToolbar = ({configBuilder, settings, stage, substage, onChange, onChangeSubstage, context}) => {
    switch (stage) {
        case STAGES.COLORS:
            return <MaterialPicker items={configBuilder.colori} onChange={(color) => onChange({color})} current={settings.color} />;
        case STAGES.LAYOUTS:
            return <LayoutPicker items={configBuilder.layout} onChange={(layout) => onChange({layout})} current={settings.layout} />;
        case STAGES.ACCESSORIES:
            return <AccessoryPicker items={configBuilder.accessori} onChange={(accessory) => onChange({accessory})} current={settings.accessory} />;
        case STAGES.WINDOWS:
            return <JackTools items={configBuilder.frutti} onChange={onChange} />;
        case STAGES.CONTENT:
            return <ContentTools substage={substage} onChangeSubstage={onChangeSubstage} onChange={onChange} settings={settings} />;
        case STAGES.CHECKOUT:
            return <PreviewTools settings={settings} context={context} onChangeNotes={(note) => onChange({note})} />;
        default:
            throw new Error(`Unsupported stage ${stage}`);
    }
};

export default StageToolbar;
