import React from 'react';
import {useTranslation} from "react-i18next";
import { DRAG_TYPES } from '../../../constants';
import JackPreview from '../JackPreview';
import DraggableItem from './DraggableItem';

const JackPicker = ({items, ...rest}) => {
    const {t} = useTranslation();
    return (
        <div {...rest}>
            <div className="label">
                {t("instructions.jack")}
            </div>
            <div className="palette-list">
                {items.map(({code, colspan, ...rest}) => (
                    <DraggableItem key={code} item={{code, colspan, type: DRAG_TYPES.JACK}} className="btn btn-link">
                        <JackPreview {...rest} ratio={2} />
                    </DraggableItem>
                ))}
            </div>
        </div>
    );
};

export default JackPicker;
