import React from 'react';
import {Formik, Form, Field} from 'formik';
import {useTranslation} from "react-i18next";

const SaveCartForm = ({onSubmit}) => {
    const {t} = useTranslation();
    return (
        <Formik initialValues={{firstname: '', lastname: '', email: '', notes: ''}} onSubmit={onSubmit}>
            <Form className="ave-form">
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="savecart_firstname">{t('form.nome')} *</label>
                        <Field type="text" className="form-control" id="savecart_firstname" required name="firstname" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="savecart_lastname">{t('form.cognome')} *</label>
                        <Field type="text" className="form-control" id="savecart_lastname" required name="lastname" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="savecart_email">{t('form.email')} *</label>
                        <Field type="email" className="form-control" id="savecart_email" required name="email" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="savecart_notes">{t('form.note')}</label>
                        <Field component="textarea" className="form-control" id="savecart_notes" name="notes" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="savecart_tos" required />
                        <label className="form-check-label" htmlFor="savecart_tos">
                            {t('form.acconsento')}<br />
                            {t('form.leggi')} <a href="/privacy" target="_blank">{t('form.privacyPolicy')}</a>
                        </label>
                    </div>
                </div>
                <button className="btn btn-primary btn-ave" type="submit">{t('buttons.sendCode')}</button>
            </Form>
        </Formik>
    );
};

export default SaveCartForm;
