import React, {useState} from 'react';
import DraggableItem from './DraggableItem';
import {DEFAULT_ITEM_SIZE, DRAG_TYPES, TRANSPARENT_IMAGE} from '../../../constants';

const DraggableImageItem = ({src, ...rest}) => {
    const [loaded, setLoaded] = useState(false);
    const [size, setSize] = useState(null);
    const [dpmm, setDpmm] = useState(null);

    function handleLoad({target: img}) {
        setLoaded(true);
        setSize([img.naturalWidth, img.naturalHeight]);
        const max = Math.max(img.naturalWidth, img.naturalHeight);
        setDpmm(max / DEFAULT_ITEM_SIZE);
    }

    if (loaded) {
        return (
            <DraggableItem item={{type: DRAG_TYPES.IMAGE, size, src, dpmm}} preview={TRANSPARENT_IMAGE} className="btn btn-link">
                <img src={src} alt={src} {...rest} />
            </DraggableItem>
        );
    }
    return (
        <img src={src} alt={src} onLoad={handleLoad} style={{opacity: 0}} {...rest} />
    );
};

export default DraggableImageItem;
