import React from 'react';
import {useTranslation} from "react-i18next";
import ToolButton from './ToolButton';
import {assetUrl} from '../../../config';

const AccessoryPicker = ({current, items, onChange}) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="label">
                {t("instructions.frames")}
            </div>
            <div className="palette-list picker">
                {items.map(({code, thumb}) => (
                    <ToolButton key={code} onClick={() => onChange(code)} active={current === code} className="btn btn-link">
                        <img src={assetUrl(thumb)} alt={code} />
                        <span>{code}</span>
                    </ToolButton>
                ))}
            </div>
        </>
    );
};

export default AccessoryPicker;
