import React from 'react';
import {useTranslation} from "react-i18next";

const ContactsTechnicalinfo = () => {
    const {t} = useTranslation();
    return (
        <div className="col">
            <p><strong><span className="testo5">{t('contacts.infoTecniche')}</span></strong><br />
            E-mail: <a className="testo5" href="inteam@ave.it">inteam@ave.it</a></p>
        </div>
    );
};

export default ContactsTechnicalinfo;
