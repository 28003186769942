export const TRANSLATIONS_IT = {
    header: {
        archive: "Archivio",
        step1: "1. Seleziona design e materiale",
        step2: "2. Seleziona modello di placca",
        step3: "3. Personalizza placca",
        archiveCode: "Codice Archivio",
        archiveCodeLabel: "Hai un codice archivio? Inseriscilo qua",
        plateCode: "Codice Placca",
        plateCodeLabel: "Inserisci il codice anche parziale della placca che desideri personalizzare",
    },
    stages: {
        sceltaColore: "Scelta colore",
        sceltaLayout: "Scelta layout",
        sceltaAccessori: "Scelta accessori",
        posizionamentoComandi: "Posizionamento simbologie e comandi",
        posizionamentoLibero: "Posizionamento libero loghi",
        anteprima: "Visione anteprima",
        laser: "Incisione Laser",
        digitale: "Stampa Digitale",
    },
    buttons: {
        annulla: "Annulla",
        ok: "OK",
        prev: "Indietro",
        next: "Avanti",
        edit: "Modifica",
        add: "Aggiungi",
        newRequest: "Nuova richiesta",
        empty: "Svuota",
        clone: "Clona",
        downloadPdf: "Download PDF",
        save: "Salva",
        sendRequest: "Invia richiesta",
        delete: "Elimina",
        modificaPersonalizzazione: "Modifica personalizzazione",
        clonaPersonalizzazione: "Clona personalizzazione",
        uploadImage: "Carica Immagine Personalizzata",
        deleteAll: "Cancella Tutto",
        sendCode: "Inviami il Codice",
        backToSelection: "TORNA ALLA PAGINA SELEZIONA DESIGN E MATERIALE",
        centerH: "Centra orizzontalmente",
        centerV: "Centra verticalmente",
        fit: "Adatta dimensioni",
    },
    instructions: {
        colors: "Seleziona tra i colori disponibili:",
        frames: "Seleziona tra le cornici disponibili:",
        jack: "Trascina i frutti nella posizione che preferisci (i frutti indicano dove si azionano i comandi touch):",
        laser: "La stampa Laser consente di stampare ad incisione sulla placca.",
        print: "La stampa Digitale consente di stampare tramite inchiostri sulla placca.",
        trascina: "Trascina i simboli nelle parti evidenziate:",
        congrats: "Complimenti",
        modified: "Hai modificato la tua personalizzazione.",
        completed: "Hai completato la tua personalizzazione.",
        warning: "Attenzione",
        lowRes: "alcune immagini sono a bassa risoluzione.",
        note: "Se desideri puoi aggiungere delle note relative a questa placca:",
        notePlaceholder: "Aggiungi qui eventuali annotazioni...",
        layout: "Seleziona tra i layout disponibili:",
        prepara: "Prepara immagine per laseratrice",
        modifica: "Modifica la placca e la sostituisce nell'archivio",
        clona: "Non sostituisce la placca, ma la copia e la aggiunge all'archivio",
    },
    cart: {
        riepilogo: "Riepilogo archivio",
        alreadyOrdered: "Questo archivio è già stato ordinato",
        note: "Note",
        preview: "Anteprima",
        name: "Nome",
        qta: "Quantità",
        info: "Per ulteriori informazioni relative a tempi e costi di tutte le personalizzazioni, comprese quelle non presenti nel software di personalizzazione placche, contattare il referente commerciale di zona <1 href=\"http://www.ave.it/rete-commerciale\" target=\"_blank\" rel=\"noopener noreferrer\">www.ave.it/rete-commerciale</1> o visitare il sito <2 href=\"http://ave.it\" target=\"_blank\" rel=\"noopener noreferrer\">www.ave.it</2> nella apposita sezione catalogo commerciale.",
        salvato: "Archivio Salvato!",
        salvatoCodice: "Complimenti hai salvato l'archivio. Utilizza il seguente codice per modificare le tue personalizzazioni:",
        compila: "Compila con i tuoi dati per ricevere un promemoria con il codice via mail:",
        istruzioni1: "Tra qualche istante riceverai una mail con il codice dell'archivio che hai salvato.",
        istruzioni2: "La mail è spedita con un programma automatico e impiega al massimo 10 minuti ad arrivare.",
        istruzioni3: "Se dopo 10 minuti ancora non la vedi allora significa che:",
        istruzioni4: "- Hai inserito un indirizzo email errato o inesistente",
        istruzioni5: "- La mail è finita nello spam",
        istruzioni6: "- Se utilizzi gmail dai un'occhiata nella scheda \"Promozioni\"",
        istruzioni7: "Controlla la tua casella di posta. A presto!",
    },
    form: {
        nome: "Nome",
        cognome: "Cognome",
        nomeCliente: "Nome Cliente",
        cognomeCliente: "Cognome Cliente",
        email: "Email",
        numeroTelefono: "Numero di Telefono",
        via: "Via/Piazza/Corso e numero civico",
        localita: "Località",
        provincia: "Provincia",
        cap: "CAP / Codice postale",
        nazione: "Nazione",
        note: "Note",
        acconsento: "Acconsento al trattamento dei miei dati personali ai sensi dell'Art. 13 Regolamento UE 2016/679.",
        leggi: "Leggi la nostra",
        privacyPolicy: "Privacy Policy",
    },
    messages: {
        requestSent: "Richiesta inviata",
        requestWarning: "Attenzione: la richiesta di personalizzazione sarà elaborata e prodotta sulla base del disegno confermato dal configuratore.",
        orderSent: "Complimenti hai inviato la tua richiesta!",
        emailinstructions: "Una volta ricevuta la mail, stampala e portala al tuo grossista o funzionario di riferimento.",
        tecnopolimero44landing: "Per la personalizzazione delle placche in Tecnopolimero della serie 44PY si prega di contattare AVE ai seguenti recapiti:",
    },
    ispirazioni: {
        title: "Selezione Design e Materiale",
        subtitle: "clicca sulla placca che vuoi personalizzare",
        placche: {
            vetro: "Placche in Vetro",
            alluminio: "Placche in Alluminio",
            corian: "Placche in Corian",
            tecnopolimero: "Placche in Tecnopolimero",
        },
    },
    plate: {
        placca: "Placca",
        placche: "Placche",
        comando: "Comando",
        materiale: "Materiale",
        modello: "Modello",
        serie: "Serie",
    },
    catalogo: {
        seleziona: "Selezione Modello di Placca",
        click: "clicca sulla placca che vuoi personalizzare",
    },
    menu: {
        privacy: "Privacy Policy",
        cookie: "Consenso Cookie",
        contacts: "Contatti",
    },
    dictionary: {
        series: {
            AVETOUCH: 'Ave Touch',
            NEWSTYLE44: 'New Style 44',
            TECNOPOLIMERO44: 'Tecnopolimero 44',
            VERA44: 'Vera 44',
            YOUNG44: 'Young 44',
            YOUNGTOUCH: 'Young Touch',
        },
        materials: {
            ALLUMINIO: 'Alluminio',
            CORIAN: 'Corian',
            TECNOPOLIMERO: 'Tecnopolimero',
            VETRO: 'Vetro',
        },
        types: {
            FINESTRA: 'Tradizionale',
            LEVETTA: 'Levetta',
            PRESETONDE: 'Presa di corrente',
            TOUCH: 'Touch',
        },
        dimensions: {
            '1MOD': '1 Comando',
            '2MOD': '2 Comandi',
            '3MOD': '3 Comandi',
            '3/6MOD' : '3/6 Comandi',
            '4MOD': '4 Comandi',
            '6MOD': '6 Comandi',
            '7MOD': '7 Comandi',
            '8MOD': '8 Comandi',
        },
        boxes: {
            '2MOD': 'Scatola 2 Moduli',
            '3MOD': 'Scatola 3 Moduli',
            '4MOD': 'Scatola 4 Moduli',
            '6(3+3)MOD' : 'Scatola 6 (3+3) Moduli',
            '7MOD': 'Scatola 7 Moduli',
        },
        layouts: {
            CC: 'CON CORNICE',
            SC: 'SENZA CORNICE',
        },
    },
    attributes: {
        ave: "AVE S.p.a.",
        originale: "Originale",
        elaborata: "Elaborata",
        soglia: "Soglia",
    },
    contacts: {
        ufficiCommerciali: "Uffici commerciali Italia",
        infoTecniche: "Informazioni tecniche",
        exportDept: "Export Department",
    },
};
