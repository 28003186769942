import React from 'react';
import PropTypes from 'prop-types';
import {Prompt} from 'react-router-dom';

class PageLeaveWarning extends React.PureComponent {
    constructor(props) {
        super(props);
        this._oldValue = null;
    }

    componentDidMount() {
        this._oldValue = window.onbeforeunload;
        window.onbeforeunload = () => this.props.message;
    }

    componentWillUnmount() {
        window.onbeforeunload = this._oldValue;
    }

    render() {
        return (
            <Prompt message={this.props.message} />
        );
    }
}

PageLeaveWarning.propTypes = {
    message: PropTypes.string.isRequired,
};

export default PageLeaveWarning;
