import React from 'react';
import {Link} from 'react-router-dom';
import qs from 'qs';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {catalogGetList} from '../actions/catalogActions';
import {assetUrl} from '../config';

const CatalogItem = ({code, material, modules, box, series, thumbnail, type, t}) => (
    <Link to={`/configuratore/${code}`} className="col-lg-4 col-md-6 catalog-item px-5 py-4">
        <div>
            <div className="img-wrapper" style={{backgroundImage: `url(${assetUrl(thumbnail) || 'https://via.placeholder.com/400x250.png'})`}} />
            <div className="row">
                <div className="col">
                    <h3>{code}</h3>
                    <div className="details text-uppercase">
                        {t(`dictionary.series.${series}`)} - {t(`dictionary.types.${type}`)} -<br />
                        {t(`dictionary.materials.${material}`)} - {t(`dictionary.dimensions.${modules}`)}
                        {box && <> -<br/> {t(`dictionary.boxes.${box}`)}</>}
                    </div>
                </div>
            </div>
        </div>
    </Link>
);

const FilterItem = ({checked, onChange, children, disabled}) => (
    <label className={classNames('d-block', {disabled, checked})}>
        <input type="checkbox" checked={checked} onChange={() => onChange(!checked)} disabled={disabled}/>
        {children}
    </label>
);

const FilterItems = ({onChange, selected, values, disabled, dictionary, t}) => (
    values.sort().map((v) => <FilterItem key={v} checked={selected.includes(v)} onChange={(checked) => onChange(checked ? selected.concat(v) : selected.filter((x) => x !== v))} disabled={disabled}> {t(`${dictionary}.${v}`)}</FilterItem>)
);

const FilterBlock = ({label, onChange, selected, values, disabled, hidden, dictionary, t}) => (
    <div className={classNames('filter-block', {'d-none' : hidden})}>
        <h2>{label}</h2>
        <FilterItems onChange={onChange} selected={selected} values={values} disabled={disabled} dictionary={dictionary} t={t}/>
    </div>
);

class Catalog extends React.Component {
    constructor(props) {
        super(props);
        const params = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        this.state = {
            materials: params.mat || [],
            modules: params.mod || [],
            series: params.s || [],
            types: params.t || [],
            code: params.q || undefined,
        };
        this.search = this.search.bind(this);
        this.setFilterMaterials = this.setFilter.bind(this, 'materials');
        this.setFilterModules = this.setFilter.bind(this, 'modules');
        this.setFilterSeries = this.setFilter.bind(this, 'series');
        this.setFilterTypes = this.setFilter.bind(this, 'types');
    }

    componentDidMount() {
        if (!this.props.plates) {
            this.props.catalogGetList();
        }
    }

    search(event) {
        this.setState({code: event.target.value});
    }

    setFilter(key, values) {
        this.setState({[key]: values}, () => {
            const data = {
                mat: this.state.materials,
                mod: this.state.modules,
                s: this.state.series,
                t: this.state.types,
            };
            const search = qs.stringify(data, {addQueryPrefix: true, arrayFormat: 'brackets'})
            this.props.history.replace({pathname: this.props.match.path, search});
        });
    }

    render() {
        if (!this.props.plates) {
            return null; // TODO: loader
        }

        const {t} = this.props;

        const filteredItems = this.props.plates.filter((plate) => {
            if (this.state.code && plate.code.toUpperCase().includes(this.state.code.toUpperCase())) {
                return true;
            }
            if (this.state.code) {
                return false;
            }

            if (this.state.materials.length && !this.state.materials.includes(plate.material)) {
                return false;
            }
            if (this.state.modules.length && !this.state.modules.includes(plate.modules)) {
                return false;
            }
            if (this.state.series.length && !this.state.series.includes(plate.series)) {
                return false;
            }
            if (this.state.types.length && !this.state.types.includes(plate.type)) {
                return false;
            }
            return true;
        });

        return (
            <div className="catalog-container container-fluid">
                <div className="filters">
                    <div>
                        <FilterBlock values={this.props.types} selected={this.state.types} onChange={this.setFilterTypes}
                            label={t("plate.comando")} disabled={true} hidden={!!this.state.code} dictionary="dictionary.types" t={t} />
                        <FilterBlock values={this.props.materials} selected={this.state.materials} onChange={this.setFilterMaterials}
                            label={t("plate.materiale")} disabled={true} hidden={!!this.state.code} dictionary="dictionary.materials" t={t} />
                        <FilterBlock values={this.props.series} selected={this.state.series} onChange={this.setFilterSeries}
                            label={t("plate.serie")} disabled={true} hidden={true} dictionary="dictionary.series" t={t} />
                        <FilterBlock values={this.props.modules} selected={this.state.modules} onChange={this.setFilterModules}
                            label={t("plate.dimensioni")} disabled={true} hidden={true} dictionary="dictionary.dimensions" t={t} />
                    </div>
                </div>
                <>
                    <div className="title-bar">
                        <h1>{t("catalogo.seleziona")}</h1>
                        <p>{t("catalogo.click")}</p>
                    </div>
                    <div className="container">
                        <p className="text-center">{filteredItems.length} {t("plate.placche")}</p>
                        <div className="row">
                            <div className="col">
                                <div className="row catalog-items">
                                    {filteredItems.map((item) => <CatalogItem key={item.code} {...item} t={t} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plates: state.catalog.list,
    materials: state.catalog.materials,
    modules: state.catalog.modules,
    series: state.catalog.series,
    types: state.catalog.types,
});

export default connect(mapStateToProps, {catalogGetList})(withTranslation()(Catalog));
