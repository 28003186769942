export const BASE_URL = process.env.REACT_APP_BASE_URL;
if (!BASE_URL) {
    console.warn('REACT_APP_BASE_URL env variable is not defined.');
}

const dpiToDpmm = (value) => value * 0.0393701;

export const THUMBNAIL_HEIGHT = 600;

export const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const MINIMUM_ITEM_SIZE = 5;
export const DEFAULT_ITEM_SIZE = 40;
export const MINIMUM_DPMM = dpiToDpmm(150);

export const STAGES = {
    COLORS: 0,
    LAYOUTS: 1,
    ACCESSORIES: 2,
    WINDOWS: 3,
    CONTENT: 4,
    CHECKOUT: 5,
};

export const CONTEXTS = {
    PLATE_CREATION: 'PLATE_CREATION',
    PLATE_EDITING: 'PLATE_EDITING',
}

export const SUBSTAGES = {
    CONTENT_PRINTING: 'CONTENT_PRINTING',
    CONTENT_LASER: 'CONTENT_LASER',
};

export const AREA_TYPES = {
    LASER: 'laser',
    PRINTING: 'printing',
};

export const CONTENT_TYPES = {
    IMAGE: 'image',
    TEXT: 'text',
};

export const DRAG_TYPES = {
    IMAGE: CONTENT_TYPES.IMAGE,
    ITEM: 'item',
    JACK: 'jack',
};

export const CART_GET_CURRENT = 'CART_GET_CURRENT';
export const CART_GET_CURRENT_SUCCESS = 'CART_GET_CURRENT_SUCCESS';
export const CART_GET_CURRENT_ERROR = 'CART_GET_CURRENT_ERROR';
export const CART_SET_CURRENT = 'CART_SET_CURRENT';
export const CART_SET_CURRENT_SUCCESS = 'CART_SET_CURRENT_SUCCESS';
export const CART_SET_CURRENT_ERROR = 'CART_SET_CURRENT_ERROR';

export const CART_ADD = 'CART_ADD';
export const CART_ADD_PROGRESS = 'CART_ADD_PROGRESS';
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_ADD_ERROR = 'CART_ADD_ERROR';

export const CART_GET_ITEM = 'CART_GET_ITEM';
export const CART_GET_ITEM_SUCCESS = 'CART_GET_ITEM_SUCCESS';
export const CART_GET_ITEM_ERROR = 'CART_GET_ITEM_ERROR';

export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM';
export const CART_DELETE_ITEM = 'CART_DELETE_ITEM';
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export const CART_UPDATE_ERROR = 'CART_UPDATE_ERROR';

export const CART_PLACE_ORDER = 'CART_PLACE_ORDER';
export const CART_PLACE_ORDER_SUCCESS = 'CART_PLACE_ORDER_SUCCESS';
export const CART_PLACE_ORDER_ERROR = 'CART_PLACE_ORDER_ERROR';

export const CATALOG_GET_LIST = 'CATALOG_GET_LIST';
export const CATALOG_GET_LIST_SUCCESS = 'CATALOG_GET_LIST_SUCCESS';
export const CATALOG_GET_LIST_ERROR = 'CATALOG_GET_LIST_ERROR';

export const CATALOG_GET_ITEM = 'CATALOG_GET_ITEM';
export const CATALOG_GET_ITEM_SUCCESS = 'CATALOG_GET_ITEM_SUCCESS';
export const CATALOG_GET_ITEM_ERROR = 'CATALOG_GET_ITEM_ERROR';

export const CART_SAVE = 'CART_SAVE';
export const CART_SAVE_SUCCESS = 'CART_SAVE_SUCCESS';
export const CART_SAVE_ERROR = 'CART_SAVE_ERROR';

export const CART_NEW = 'CART_NEW';
export const CART_NEW_SUCCESS = 'CART_NEW_SUCCESS';
export const CART_NEW_ERROR = 'CART_NEW_ERROR';