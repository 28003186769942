import find from 'lodash/find';

function buildPlate(config, settings) {
    const layers = [];
    let dark = false;
    if (settings.color) {
        const color = find(config.colori, {code: settings.color});
        if (color.dark) {
            dark = true;
        }
        layers.push(...color.layers);
    }
    if (settings.layout) {
        const layout = find(config.layout, {code: settings.layout});
        // Check for layer override for current color
        const color = find(config.colori, {code: settings.color});
        const layoutOverride = color.layout ? find(color.layout, {code: settings.layout}) : null;
        layers.push(...layoutOverride ? layoutOverride.layers : layout.layers);
    }
    if (settings.accessory) {
        const accessory = find(config.accessori, {code: settings.accessory});
        layers.push(...accessory.layers);
    }
    for (const area of config.areas) {
        for (const areaType of area.types) {
            layers.push({
                areas: [area.area],
                type: areaType,
            });
        }
    }

    if (settings.items && settings.items.length > 0) {
        for (const item of settings.items) {
            layers.push({
                content: [
                    {
                        id: item.id,
                        type: item.type,
                        src: item.src,
                        pos: item.pos,
                        size: item.size,
                        clip: item.area,
                    }
                ],
                type: item.areaType,
            })
        }
    }

    return {
        width: config.larghezza,
        height: config.altezza,
        dark,
        layers,
    };
}

export default buildPlate;
