import * as constants from '../constants';

const DEFAULT_STATE = {
    list: null,
    materials: null,
    modules: null,
    plates: {},
    series: null,
    types: null,
};

const extractField = (items, field) => [...new Set(items.map((item) => item[field]))];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case constants.CATALOG_GET_LIST_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload.items,
                materials: extractField(action.payload.items, 'material'),
                modules: extractField(action.payload.items, 'modules'),
                series: extractField(action.payload.items, 'series'),
                types: extractField(action.payload.items, 'type'),
            });
        case constants.CATALOG_GET_ITEM_SUCCESS:
            return Object.assign({}, state, {
                plates: Object.assign({}, state.plates, {[action.payload.item.code]: action.payload.item}),
            })
        default:
            return state;
    }
};
