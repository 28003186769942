import React from 'react';
import StageToolbar from './tools/StageToolbar';
import {hasSubstage} from '../../lib/plateUtils';
import {STAGES, SUBSTAGES} from '../../constants';
import {useTranslation} from "react-i18next";
import StageLink from './nav/StageLink';

const CONTENT_SUBSTAGES_CONFIG = [
    {id: SUBSTAGES.CONTENT_LASER, label: 'laser'},
    {id: SUBSTAGES.CONTENT_PRINTING, label: 'digitale'},
];

const ConfiguratorToolbar = ({plate, onChange, settings, stage, substage, onChangeSubstage, context}) => {
    const {t} = useTranslation();
    return (
        <>
            {stage === STAGES.CONTENT && (
                <div className="cfg-toolbar__tabs">
                    {CONTENT_SUBSTAGES_CONFIG.filter(({id}) => hasSubstage(plate, id)).map(({id, label}) => (
                        <StageLink stage={id} active={substage === id} onClick={onChangeSubstage} key={id}>{t(`stages.${label}`)}</StageLink>
                    ))}
                </div>
            )}
            <div className="cfg-toolbar row">
                <div className="col cfg-toolbar__tools">
                    <StageToolbar configBuilder={plate} onChange={onChange} settings={settings}
                        stage={stage} substage={substage}
                        onChangeSubstage={onChangeSubstage} context={context}/>
                </div>
            </div>
        </>
    );
};

export default ConfiguratorToolbar;
