import {assetUrl} from '../config';

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

const LASER_DEFAULT = [...Array(140).keys()].map((i) => assetUrl(`/library/laser/S.${pad(i + 1, 5)}.png`));

const PRINTING_DEFAULT = {
    library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/S.${pad(i + 1, 5)}.png`)),
    name: 'S',
    rgb: '#'
};

/* 
 * Add different libraries for different colors, e.g.:
 *     ALS: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/ALS.${pad(i + 1, 5)}.png`)),
 *     B: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/B.${pad(i + 1, 5)}.png`)),
 */
const PRINTING_SYMBOLS_COLORS = {
    B: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/B.${pad(i + 1, 5)}.png`)),
        name: 'BIANCO',
        rgb: '#ffffff'},
    GD: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/GD.${pad(i + 1, 5)}.png`)),
        name: 'NERO',
        rgb: '#95a3ab'},
    GY: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/GY.${pad(i + 1, 5)}.png`)),
        name: 'GREY YOUNG',
        rgb: '#647883'},
    N: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/N.${pad(i + 1, 5)}.png`)),
        name: 'GREY DOMUS',
        rgb: '#231f20'},
    R7023: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/R7023.${pad(i + 1, 5)}.png`)),
        name: 'RAL 7023',
        rgb: '#7a7e6f'},
    R7023D: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/R7023D.${pad(i + 1, 5)}.png`)),
        name: 'RAL 7023 DARK',
        rgb: '#676a5e'},
    R7023L: {
        library: [...Array(140).keys()].map((i) => assetUrl(`/library/printing/R7023L.${pad(i + 1, 5)}.png`)),
        name: 'RAL 7023 LIGHT',
        rgb: '#9fa490'},
}

const PRINTING_COLORS = {
    'AVN': 'B',
    'AVR': 'N',
    'B': 'GY',
    'B/3D': 'GY',
    'BEG/3D': 'N',
    'BT': 'GD',
    'CBC/3D': 'B',
    'CBS/3D': 'R7023',
    'CNR': 'B',
    'GM': 'R7023D',
    'GOLD': 'N',
    'GSO': 'GD',
    'GTK': 'R7023L',
    'GTKC': 'R7023L',
    'GTKT': 'R7023L',
    'NAL': 'R7023L',
    'ONC/3D': 'B',
    'SA': 'N',
    'SAL': 'N',
    'ANS': 'R7023',
    'ALS': 'R7023D',
    'BL': 'GD',
    'GO': 'R7023D',
    'VO': 'R7023',
};

export const getLaserLibrary = () => LASER_DEFAULT;
export const getPrintingLibrary = ({color}) => (PRINTING_SYMBOLS_COLORS[PRINTING_COLORS[color]] && PRINTING_SYMBOLS_COLORS[PRINTING_COLORS[color]].library) || PRINTING_DEFAULT.library;
export const getPrintingLibraryCode = ({color}) => PRINTING_COLORS[color] || PRINTING_DEFAULT.name;
export const getPrintingLibraryLabel = ({color}) => (PRINTING_SYMBOLS_COLORS[PRINTING_COLORS[color]] && PRINTING_SYMBOLS_COLORS[PRINTING_COLORS[color]].rgb) || PRINTING_DEFAULT.rgb;
