import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {SUBSTAGES} from '../../../constants';
import LibraryPicker from './LibraryPicker';
import * as libraries from '../../../data/libraries';
import Toolbar from './Toolbar';
import ToolbarSeparator from './ToolbarSeparator';
import ToolbarButton from './ToolbarButton';
import {substageToAreaType} from '../../../lib/plateUtils';
import ToolbarFileButton from './ToolbarFileButton';
import ToolbarFileButtonThreshold from './ToolbarFileButtonThreshold';

const SubstageLibraryToolbar = ({library, onChange, settings, substage, label}) => {
    const [items, setItems] = useState(library);
    const [scrollablePickerId] = useState(`substage-library-picker_${Date.now()}`);

    function handleChangeFile(result) {
        if (!items.includes(result)) {
            setItems([result].concat(items));
            document.getElementById(scrollablePickerId).scrollTo({left: 0, top: 0});
        }
    }

    function clear() {
        const areaType = substageToAreaType(substage);
        onChange({items: settings.items.filter((i) => i.areaType !== areaType)})
    }

    const {t} = useTranslation();

    return (
        <Toolbar>
            <LibraryPicker items={items} substage={substage} className="col" style={{minWidth: 0}} id={scrollablePickerId} label={label}/>
            <ToolbarSeparator />
            <div className="col-auto">
                {substage === SUBSTAGES.CONTENT_LASER ?
                    <ToolbarFileButtonThreshold onChange={handleChangeFile} /> :
                    <ToolbarFileButton onChange={handleChangeFile} /> }
                <ToolbarButton onClick={clear}>
                    <ToolbarButton.Image image="delete" />
                    <ToolbarButton.Label>{t("buttons.deleteAll")}</ToolbarButton.Label>
                </ToolbarButton>
            </div>
        </Toolbar>
    );
};

const SubstageLibraryPicker = ({onChange, settings, substage}) => {
    switch (substage) {
        case SUBSTAGES.CONTENT_PRINTING:
            return <SubstageLibraryToolbar settings={settings} substage={substage} library={libraries.getPrintingLibrary(settings)}
                label={libraries.getPrintingLibraryLabel(settings)} onChange={onChange} />;
        case SUBSTAGES.CONTENT_LASER:
            return <SubstageLibraryToolbar settings={settings} substage={substage} library={libraries.getLaserLibrary(settings)}
                onChange={onChange} />;
        default:
            throw new Error(`Invalid substage "${substage}"`);
    }
};

const ContentTools = ({onChange, settings, substage}) => (
    // `key` is required to rebuild picker when switching substage
    <SubstageLibraryPicker key={substage} onChange={onChange} settings={settings} substage={substage} />
);

export default ContentTools;
