import {AREA_TYPES, CONTENT_TYPES, MINIMUM_DPMM, STAGES, SUBSTAGES} from "../constants";

export function getInitialSettings(configBuilder) {
    return {
        color: configBuilder.defaultColor || (configBuilder.colori.length && configBuilder.colori[0].code),
        layout: configBuilder.layout.length && configBuilder.layout[0].code,
        accessory: configBuilder.accessori.length && configBuilder.accessori[0].code,
        jacks: [],
        items: [],
    };
}

export function hasStage(configBuilder, stage) {
    switch (stage) {
        case STAGES.COLORS:
            return !!(configBuilder.colori && configBuilder.colori.length > 1);
        case STAGES.LAYOUTS:
            return !!(configBuilder.layout && configBuilder.layout.length > 1);
        case STAGES.ACCESSORIES:
            return !!(configBuilder.accessori && configBuilder.accessori.length > 1);
        case STAGES.WINDOWS:
            return !!(configBuilder.finestre && configBuilder.finestre.length > 0);
        case STAGES.CONTENT:
        case STAGES.CHECKOUT:
            return true;
        default:
            throw new Error(`Unknown stage ${stage}`);
    }
}

export function hasSubstage(configBuilder, substage) {
    let areaType;
    switch (substage) {
        case SUBSTAGES.CONTENT_LASER:
            areaType = AREA_TYPES.LASER;
            break;
        case SUBSTAGES.CONTENT_PRINTING:
            areaType = AREA_TYPES.PRINTING;
            break;
        default:
            throw new Error(`Unknown substage ${substage}`);
    }
    const result = configBuilder.areas.some(({types}) => types.includes(areaType));
    return result;
}

export function getPrevStage(configBuilder, stage) {
    do {
        stage--;
    } while (stage > 0 && !hasStage(configBuilder, stage));
    if (stage < 0) {
        return null;
    }
    return stage;
}

export function getNextStage(configBuilder, stage) {
    do {
        stage++;
    } while (stage < Object.keys(STAGES).length && !hasStage(configBuilder, stage));
    if (stage >= Object.keys(STAGES).length) {
        return null;
    }
    return stage;
}

export function getInitialStage(configBuilder) {
    return getNextStage(configBuilder, -1);
}

export function hasPrevStage(configBuilder, stage) {
    return getPrevStage(configBuilder, stage) !== null;
}

export function hasNextStage(configBuilder, stage) {
    return getNextStage(configBuilder, stage) !== null;
}

export function getSubstage(configBuilder, stage, substage) {
    switch (stage) {
        case STAGES.CONTENT:
            if (substage === SUBSTAGES.CONTENT_PRINTING || substage === SUBSTAGES.CONTENT_LASER) {
                return substage;
            }
            return hasSubstage(configBuilder, SUBSTAGES.CONTENT_LASER) ? SUBSTAGES.CONTENT_LASER : SUBSTAGES.CONTENT_PRINTING;
        default:
            return null;
    }
}

export const substageToAreaType = (substage) => {
    switch (substage) {
        case SUBSTAGES.CONTENT_LASER:
            return AREA_TYPES.LASER;
        case SUBSTAGES.CONTENT_PRINTING:
            return AREA_TYPES.PRINTING;
        default:
            throw new Error(`Unknown substage "${substage}".`);
    }
};

export const hasLowResImage = (plate) => plate.items
    .filter(({naturalSize, type}) => naturalSize && type === CONTENT_TYPES.IMAGE)
    .map(({naturalSize, size}) => Math.min(naturalSize[0] / size[0], naturalSize[1] / size[1]))
    .some((dpmm) => dpmm < MINIMUM_DPMM);
