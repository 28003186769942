import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const InspirationItem = ({name, material, type, link, thumbnail, showLabelComando=true}) => {
    const {t} = useTranslation();
    return (
        <Link to={link} className="col-lg-3 col-md-6 catalog-item py-4">
            <div>
                <div className="img-wrapper" style={{backgroundImage: `url('${thumbnail}')`}} />
                <div className="row">
                    <div className="col">
                        <h3>{name}</h3>
                        <div className="details">
                            <b>{showLabelComando && <>{t("plate.comando")}: </>}{type}</b><br />
                            <b>{t("plate.materiale")}: {material}</b>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

const Inspiration = () => {
    const {t} = useTranslation();
    return (
        <div className="container-fluid px-0">
            <div className="title-bar">
                <h1>{t("ispirazioni.title")}</h1>
                <p>{t("ispirazioni.subtitle")}</p>
            </div>
            <div className="container">
                <p className="text-center mt-5 mb-2 font-italic">{t("ispirazioni.placche.vetro")}</p>
                <div className="row justify-content-md-center catalog-items">
                    <InspirationItem name={t(`dictionary.series.AVETOUCH`)} material={t(`dictionary.materials.VETRO`)} type={t(`dictionary.types.TOUCH`)} link="/catalogo?mat%5B%5D=VETRO&amp;s%5B%5D=AVETOUCH&amp;t%5B%5D=TOUCH" thumbnail="/plate-types/v1.png" />
                    <InspirationItem name={t(`dictionary.series.VERA44`)} material={t(`dictionary.materials.VETRO`)} type={t(`dictionary.types.FINESTRA`)} link="/catalogo?mat%5B%5D=VETRO&amp;s%5B%5D=VERA44&amp;t%5B%5D=FINESTRA" thumbnail="/plate-types/v2.png"  />
                    <InspirationItem name={t(`dictionary.series.NEWSTYLE44`)} material={t(`dictionary.materials.VETRO`)} type={t(`dictionary.types.LEVETTA`)} link="/catalogo?mat%5B%5D=VETRO&amp;s%5B%5D=NEWSTYLE44&amp;t%5B%5D=LEVETTA" thumbnail="/plate-types/v3.png"  />
                    <InspirationItem name={t(`dictionary.series.NEWSTYLE44`)} material={t(`dictionary.materials.VETRO`)} type={t(`dictionary.types.PRESETONDE`)} link="/catalogo?mat%5B%5D=VETRO&amp;s%5B%5D=NEWSTYLE44&amp;t%5B%5D=PRESETONDE" thumbnail="/plate-types/v4.png" showLabelComando={false} />
                </div>

                <p className="text-center mt-5 mb-2 font-italic">{t("ispirazioni.placche.alluminio")}</p>
                <div className="row justify-content-md-center catalog-items">
                    <InspirationItem name={t(`dictionary.series.AVETOUCH`)} material={t(`dictionary.materials.ALLUMINIO`)} type={t(`dictionary.types.TOUCH`)} link="/catalogo?mat%5B%5D=ALLUMINIO&amp;s%5B%5D=AVETOUCH&amp;t%5B%5D=TOUCH" thumbnail="/plate-types/a1.png" />
                    <InspirationItem name={t(`dictionary.series.VERA44`)} material={t(`dictionary.materials.ALLUMINIO`)} type={t(`dictionary.types.FINESTRA`)} link="/catalogo?mat%5B%5D=ALLUMINIO&amp;s%5B%5D=VERA44&amp;t%5B%5D=FINESTRA" thumbnail="/plate-types/a2.png" />
                    <InspirationItem name={t(`dictionary.series.NEWSTYLE44`)} material={t(`dictionary.materials.ALLUMINIO`)} type={t(`dictionary.types.LEVETTA`)} link="/catalogo?mat%5B%5D=ALLUMINIO&amp;s%5B%5D=NEWSTYLE44&amp;t%5B%5D=LEVETTA" thumbnail="/plate-types/a3.png" />
                    <InspirationItem name={t(`dictionary.series.NEWSTYLE44`)} material={t(`dictionary.materials.ALLUMINIO`)} type={t(`dictionary.types.PRESETONDE`)} link="/catalogo?mat%5B%5D=ALLUMINIO&amp;s%5B%5D=NEWSTYLE44&amp;t%5B%5D=PRESETONDE" thumbnail="/plate-types/a4.png" showLabelComando={false}/>
                </div>

                <p className="text-center mt-5 mb-2 font-italic">{t("ispirazioni.placche.corian")}</p>
                <div className="row justify-content-md-center catalog-items">
                    <InspirationItem name={t(`dictionary.series.VERA44`)} material={t(`dictionary.materials.CORIAN`)} type={t(`dictionary.types.FINESTRA`)} link="/catalogo?mat%5B%5D=CORIAN&amp;s%5B%5D=VERA44&amp;t%5B%5D=FINESTRA" thumbnail="/plate-types/c1.png" />
                    <InspirationItem name={t(`dictionary.series.NEWSTYLE44`)} material={t(`dictionary.materials.CORIAN`)} type={t(`dictionary.types.LEVETTA`)} link="/catalogo?mat%5B%5D=CORIAN&amp;s%5B%5D=NEWSTYLE44&amp;t%5B%5D=LEVETTA" thumbnail="/plate-types/c2.png" />
                </div>

                <p className="text-center mt-5 mb-2 font-italic">{t("ispirazioni.placche.tecnopolimero")}</p>
                <div className="row justify-content-md-center catalog-items">
                    <InspirationItem name={t(`dictionary.series.YOUNGTOUCH`)} material={t(`dictionary.materials.TECNOPOLIMERO`)} type={t(`dictionary.types.TOUCH`)} link="/catalogo?mat%5B%5D=TECNOPOLIMERO&amp;s%5B%5D=YOUNGTOUCH&amp;t%5B%5D=TOUCH" thumbnail="/plate-types/t1.png" />
                    <InspirationItem name={t(`dictionary.series.YOUNG44`)} material={t(`dictionary.materials.TECNOPOLIMERO`)} type={t(`dictionary.types.FINESTRA`)} link="/catalogo?mat%5B%5D=TECNOPOLIMERO&amp;s%5B%5D=YOUNG44&amp;t%5B%5D=FINESTRA" thumbnail="/plate-types/t3.png" />
                    <InspirationItem name={t(`dictionary.series.TECNOPOLIMERO44`)} material={t(`dictionary.materials.TECNOPOLIMERO`)} type={t(`dictionary.types.FINESTRA`)} link="/44PY" thumbnail="/plate-types/t2.png" />
                </div>
            </div>
        </div>
    );
};

export default Inspiration;
