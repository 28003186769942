import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Canvas from './Canvas';
import buildPlate from '../../lib/buildPlate';
import StageNavBar from './nav/StageNavBar';
import StageNavButtons from './StageNavButtons';
import {getInitialSettings, getInitialStage, getNextStage, getPrevStage, getSubstage} from '../../lib/plateUtils';
import {generatePlateThumbnail} from '../../lib/utils';
import ConfiguratorToolbar from './ConfiguratorToolbar';
import ImageCache from "../../lib/ImageCache";
import Renderer from '../../lib/Renderer';
import {CONTEXTS} from '../../constants';
import PageLeaveWarning from './PageLeaveWarning';
import {getPrintingLibraryCode} from '../../data/libraries';

class Configurator extends React.Component {
    constructor(props) {
        super(props);
        const imageCache = new ImageCache(debounce(() => this.forceUpdate(), 100));
        this._renderer = new Renderer(imageCache);
        const stage = getInitialStage(props.plate);
        this.state = {
            selectedId: null,
            settings: props.settings || getInitialSettings(props.plate),
            stage,
            substage: getSubstage(props.plate, stage),
            context: props.location.pathname.split('/')[3] === 'modifica' ? CONTEXTS.PLATE_EDITING : CONTEXTS.PLATE_CREATION,
            changed: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStage = this.handleChangeStage.bind(this);
        this.handleChangeSubstage = this.handleChangeSubstage.bind(this);
        this.handlePrevStage = this.handlePrevStage.bind(this);
        this.handleNextStage = this.handleNextStage.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    replaceLibraryItems(settings) {
        const printItemsColor = getPrintingLibraryCode(settings);
        const printingLibraryItems = settings.items.filter(i => i.src.match(/\/library\/printing\//));
        for (const item of printingLibraryItems) {
            item.src = item.src.replace(/(.*\/library\/printing\/)[a-zA-Z0-9]*(\.\d{5}\.png)/, `$1${printItemsColor}$2`);
        }
    }

    handleChange(newSettings) {
        const settings = Object.assign({}, this.state.settings, newSettings);
        this.replaceLibraryItems(settings);
        this.setState({
            changed: true,
            settings,
        });
    }

    handlePrevStage() {
        const stage = getPrevStage(this.props.plate, this.state.stage);
        this.setState({
            stage,
            substage: getSubstage(this.props.plate, stage, this.state.substage),
        });
    }
    handleNextStage() {
        const stage = getNextStage(this.props.plate, this.state.stage);
        this.setState({
            stage,
            substage: getSubstage(this.props.plate, stage, this.state.substage),
        });
    }
    handleChangeStage(stage) {
        this.setState({
            stage,
            substage: getSubstage(this.props.plate, stage, this.state.substage),
        });
    }

    handleChangeSubstage(substage) {
        this.setState({
            substage: getSubstage(this.props.plate, this.state.stage, substage),
        });
    }

    handleSelect(id) {
        this.setState({selectedId: id});
    }

    handleSubmit() {
        const plate = buildPlate(this.props.plate, this.state.settings);
        const thumbnail = generatePlateThumbnail(this._renderer, plate);
        this.props.onSubmit(this.state.settings, thumbnail);
    }

    render() {
        const plate = buildPlate(this.props.plate, this.state.settings);
        const {canvasHeight, submitting} = this.props;
        return (
            <>
                <StageNavBar configBuilder={this.props.plate} onChangeStage={this.handleChangeStage} stage={this.state.stage} />
                <div>
                    <div style={{height: canvasHeight}} className={classNames('cfg__canvas-container', {'cfg__canvas-container__dark': plate.dark})}>
                        <StageNavButtons plate={this.props.plate} stage={this.state.stage} context={this.state.context}
                            onPrevStage={this.handlePrevStage}
                            onNextStage={this.handleNextStage}
                            onSubmit={this.handleSubmit} />
                        <Canvas configBuilder={this.props.plate} plate={plate} stage={this.state.stage} substage={this.state.substage}
                            settings={this.state.settings}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            selectedId={this.state.selectedId}
                            renderer={this._renderer}
                            height={canvasHeight} />
                        
                        
                    </div>
                    <ConfiguratorToolbar plate={this.props.plate} onChange={this.handleChange} settings={this.state.settings}
                        stage={this.state.stage} substage={this.state.substage}
                        onChangeSubstage={this.handleChangeSubstage} onSubmit={this.handleSubmit} context={this.state.context}
                    />
                </div>
                {!submitting && this.state.changed && <PageLeaveWarning message="Attenzione, se torni indietro dovrai ripartire dalla scelta di design e materiale della placca e perderai le personalizzazione fatte fino ad ora!" />}
            </>
        );
    }
}

Configurator.propTypes = {
    canvasHeight: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    plate: PropTypes.object,
    settings: PropTypes.object,
    location: PropTypes.object,
    submitting: PropTypes.bool,
};

export default Configurator;
