import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {TRANSLATIONS_EN} from "./locales/en/translations";
import {TRANSLATIONS_IT} from "./locales/it/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: TRANSLATIONS_EN,
            },
            it: {
                translations: TRANSLATIONS_IT,
            }
        },
        react: {
            useSuspense: false
        },
        fallbackLng: 'en',
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;