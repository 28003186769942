import nanoid from 'nanoid';
import {THUMBNAIL_HEIGHT} from '../constants';

/**
 * @param {CanvasImageSource} image
 * @param {string} color
 * @returns {CanvasImageSource}
 */
export const multiplyImage = (image, color) => {
    const canvas = document.createElement('canvas');
    if (image.complete && image.naturalWidth > 0) {
        const multiplyCanvas = document.createElement('canvas');
        multiplyCanvas.width = image.naturalWidth;
        multiplyCanvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        const multiplyCtx = multiplyCanvas.getContext('2d');
        multiplyCtx.drawImage(image, 0, 0, multiplyCanvas.width, multiplyCanvas.height);
        multiplyCtx.globalCompositeOperation = 'multiply';
        multiplyCtx.fillStyle = color;
        multiplyCtx.fillRect(0, 0, multiplyCanvas.width, multiplyCanvas.height);

        // Mask canvas
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'source-in';
        ctx.drawImage(multiplyCanvas, 0, 0);
    }
    return canvas;
};

export const generateId = () => nanoid();

/**
 * @param {ImageData} source Source image data
 * @param {Number} threshold From 0 to 1
 * @param {HTMLCanvasElement} canvas The working (output) canvas
 * @param {int[]} color Ouput color
 * @returns {HTMLCanvasElement}
 */
export const generateThresholdImage = (source, threshold, canvas, color) => {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const workingImageData = new ImageData(source.width, source.height);
    const sourceData = source.data;
    const workingData = workingImageData.data;
    let i = 0;
    for (let y = 0; y < canvas.height; ++y) {
        for (let x = 0; x < canvas.width; ++x, i += 4) {
            const luminance = (sourceData[i] * 0.2126 + sourceData[i + 1] * 0.7152 + sourceData[i + 2] * 0.0722) / 255;
            const alpha = sourceData[i + 3] / 255;
            const value = 1 * (1 - alpha) + luminance * alpha; // white * (1 - alpha) + color * alpha
            if (value < threshold) {
                workingData[i] = color[0];
                workingData[i + 1] = color[1];
                workingData[i + 2] = color[2];
                workingData[i + 3] = 255; // alpha
            }
        }
    }

    ctx.putImageData(workingImageData, 0, 0);

    return canvas;
};

export const getThresholdBounds = (canvas) => {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const bounds = {top: canvas.height, bottom: 0, left: canvas.width, right: 0};
    for (let i = 0; i < imageData.data.length; i += 4) {
        if (imageData.data[i + 3] !== 0) {
            const x = (i / 4) % canvas.width;
            const y = ~~((i / 4) / canvas.width);
            if (bounds.top > y) {
                bounds.top = y;
            }
            if (bounds.bottom < y) {
                bounds.bottom = y;
            }
            if (x < bounds.left) {
                bounds.left = x;
            }
            if (bounds.right < x) {
                bounds.right = x;
            }
        }
    }
    return bounds;
};

/**
 * @param {HTMLCanvasElement} source The input full-sized canvas
 * @returns {HTMLCanvasElement} A new canvas automatically cropped
 */
export const createCroppedCanvas = (source) => {
    const bounds = getThresholdBounds(source);
    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = bounds.right - bounds.left;
    croppedCanvas.height = bounds.bottom - bounds.top;
    const ctx = croppedCanvas.getContext('2d');
    ctx.clearRect(0, 0, croppedCanvas.width, croppedCanvas.height);
    ctx.drawImage(source,
        bounds.left, bounds.top, croppedCanvas.width, croppedCanvas.height,
        0, 0, croppedCanvas.width, croppedCanvas.height,
    );
    return croppedCanvas;
};

/**
 * @param {object} renderer
 * @param {object} plate
 * @param {number} height 
 * @return {string} Base64 encoded PNG image
 */
export const generatePlateThumbnail = (renderer, plate, height = THUMBNAIL_HEIGHT) => {
    const ratio = height / plate.height;
    const canvas = document.createElement('canvas');
    canvas.width = plate.width * ratio;
    canvas.height = height;
    renderer.render(canvas, plate, {hiddenContents: []});
    const dataUrl = canvas.toDataURL('image/png');
    const base64 = dataUrl.replace(/^data:image\/(png|jpg);base64,/, '')
    return base64;
};
