import {all, call, put, takeLatest, debounce} from 'redux-saga/effects';
import * as actions from '../actions/cartActions';
import * as api from '../api';
import * as constants from '../constants';

function* cartAdd(action) {
    try {
        let result;
        if (action.payload.cartItemId) {
            result = yield call(api.cartUpdateItem, action.payload.cartItemId, {
                plate: action.payload.plate,
                data: action.payload.item,
                thumbnail: action.payload.thumbnail,
                quantity: action.payload.quantity,
                note: action.payload.note,
            });
        } else {
            result = yield call(api.cartAdd, {
                plate: action.payload.plate,
                data: action.payload.item,
                thumbnail: action.payload.thumbnail,
                quantity: action.payload.quantity,
                note: action.payload.note,
            });
        }
        yield put(actions.cartAddSuccess(result));
    } catch (err) {
        yield put(actions.cartAddError(err));
    }
}

function* cartGetCurrent() {
    try {
        const result = yield call(api.cartGetCurrent);
        yield put(actions.cartGetCurrentSuccess(result));
    } catch (err) {
        yield put(actions.cartGetCurrentError(err));
    }
}

function* cartSetCurrent(action) {
    try {
        const result = yield call(api.cartSetCurrent, action.payload.code);
        yield put(actions.cartSetCurrentSuccess(result));
    } catch (err) {
        yield put(actions.cartSetCurrentError(err));
    }
}

function* cartUpdateItem(action) {
    try {
        const result = yield call(api.cartUpdateItem, action.payload.itemId, action.payload.values);
        yield put(actions.cartUpdateSuccess(result));
    } catch (err) {
        yield put(actions.cartUpdateError(err));
    }
}

function* cartDeleteItem(action) {
    try {
        const result = yield call(api.cartDeleteItem, action.payload.itemId);
        yield put(actions.cartUpdateSuccess(result));
    } catch (err) {
        yield put(actions.cartUpdateError(err));
    }
}

function* cartPlaceOrder(action) {
    try {
        const result = yield call(api.cartPlaceOrder, action.payload.values);
        yield put(actions.cartPlaceOrderSuccess(result));
    } catch (err) {
        yield put(actions.cartPlaceOrderError(err));
    }
}

const apiCall = (type, fn, successAction, errorAction) => takeLatest(type, function* (action) {
    try {
        const result = yield fn(action);
        yield put(successAction(result));
    } catch (err) {
        yield put(errorAction(err));
    }
});

export default function* cartSagas() {
    yield all([
        takeLatest(constants.CART_ADD, cartAdd),
        takeLatest(constants.CART_GET_CURRENT, cartGetCurrent),
        takeLatest(constants.CART_SET_CURRENT, cartSetCurrent),
        debounce(1000, constants.CART_UPDATE_ITEM, cartUpdateItem),
        takeLatest(constants.CART_DELETE_ITEM, cartDeleteItem),
        takeLatest(constants.CART_PLACE_ORDER, cartPlaceOrder),
        apiCall(constants.CART_SAVE,
            (action) => call(api.cartSave, action.payload.values),
            actions.cartSaveSuccess, actions.cartSaveError),
        apiCall(constants.CART_NEW,
            (action) => call(api.cartNew, action.payload),
            actions.cartNewSuccess, actions.cartNewError),
        apiCall(constants.CART_GET_ITEM,
            (action) => call(api.cartGetItem, action.payload.itemId),
            actions.cartGetItemSuccess, actions.cartGetItemError),
    ]);
}
