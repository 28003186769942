import React from 'react';
import {Link} from 'react-router-dom';
import {hasNextStage, hasPrevStage} from '../../lib/plateUtils';
import {CONTEXTS, STAGES} from '../../constants';
import {useTranslation} from "react-i18next";

const StageNavButtons = ({plate, stage, context, onPrevStage, onNextStage, onSubmit}) => {
    const {t} = useTranslation();
    return (
        <>
            {!hasPrevStage(plate, stage) && (
                <Link to={`/catalogo?mat=${plate.materiale}&s=${plate.serie}&t=${plate.tipologia}`} className="btn btn-link cfg__nav-button">
                    <span>&lt; {t("buttons.prev")}</span>
                </Link>
            )}
            {hasPrevStage(plate, stage) && (
                <button className="btn btn-link cfg__nav-button" onClick={onPrevStage}>
                    <span>&lt; {t("buttons.prev")}</span>
                </button>
            )}
            {hasNextStage(plate, stage) && (
                <button className="btn btn-link cfg__nav-button right" onClick={onNextStage}>
                    <span>{t("buttons.next")} &gt;</span>
                </button>
            )}
            {stage === STAGES.CHECKOUT && (
                <button className="btn btn-link cfg__nav-button right" onClick={onSubmit}>
                    {context === CONTEXTS.PLATE_EDITING ? `${t("buttons.edit")} >` : `${t("buttons.add")} >`}
                </button>
            )}
        </>
    );
};

export default StageNavButtons;
