import React from 'react';
import {Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';
import Cart from './Cart';
import SaveCart from './SaveCart';
import PlaceOrder from './PlaceOrder';
import CartEmpty from './cart/CartEmpty';

class CartRoutes extends React.Component {
    render() {
        const {cart, loading} = this.props;
        if (loading) {
            return null; // TODO loader (?)
        }
        if (!cart || cart.items.length === 0) {
            return (
                <Switch>
                    <Route path="/carrello/conferma" render={() => <PlaceOrder cart={cart} />} />
                    <Route component={CartEmpty} />
                </Switch>
            );
        }
        return (
            <Switch>
                <Route path="/carrello/codice" render={() => <SaveCart cart={cart} />} />
                <Route path="/carrello/conferma" render={() => <PlaceOrder cart={cart} />} />
                <Route path="/carrello" render={() => <Cart cart={cart} />} />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart.current,
    loading: state.cart.loading,
});

export default connect(mapStateToProps)(CartRoutes);
