import * as constants from '../constants';

const DEFAULT_STATE = {
    current: null,
    error: null,
    item: null,
    loading: true,
    progress: 0,
    submitting: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case constants.CART_GET_CURRENT_SUCCESS:
        case constants.CART_SET_CURRENT_SUCCESS:
        case constants.CART_NEW_SUCCESS:
        case constants.CART_UPDATE_SUCCESS:
            return Object.assign({}, state, {current: action.payload.item, loading: false});
        case constants.CART_GET_CURRENT:
            return Object.assign({}, state, {loading: true});
        case constants.CART_GET_CURRENT_ERROR:
        case constants.CART_PLACE_ORDER_SUCCESS:
            return Object.assign({}, state, {current: null, loading: false});
        case constants.CART_ADD:
            return Object.assign({}, state, {submitting: true, progress: 0});
        case constants.CART_ADD_SUCCESS:
            return Object.assign({}, state, {current: action.payload.item, submitting: false, error: null});
        case constants.CART_ADD_ERROR:
            return Object.assign({}, state, {submitting: false, error: action.payload.error});
        case constants.CART_ADD_PROGRESS:
            return Object.assign({}, state, {progress: action.payload.progress});
        case constants.CART_GET_ITEM:
            return Object.assign({}, state, {item: null});
        case constants.CART_GET_ITEM_SUCCESS:
            return Object.assign({}, state, {item: action.payload.item});
        default:
            return state;
    }
};
