export const TRANSLATIONS_EN = {
    header: {
        archive: "Archive",
        step1: "1. Select design and material",
        step2: "2. Select plate model",
        step3: "3. Customize plate",
        archiveCode: "Archive Code",
        archiveCodeLabel: "Do you have an archive code? Insert it here",
        plateCode: "Plate Code",
        plateCodeLabel: "Type the plate code for the item you would like to customize",
    },
    stages: {
        sceltaColore: "Color",
        sceltaLayout: "Layout",
        sceltaAccessori: "Accessories",
        posizionamentoComandi: "Commands and Symbols",
        posizionamentoLibero: "Logos positioning",
        anteprima: "Preview",
        laser: "Laser Engraving",
        digitale: "Digital Printing",
    },
    buttons: {
        annulla: "Cancel",
        ok: "OK",
        prev: "Prev",
        next: "Next",
        edit: "Edit",
        add: "Add",
        newRequest: "New request",
        empty: "Empty",
        clone: "Clone",
        downloadPdf: "Download PDF",
        save: "Save",
        sendRequest: "Send request",
        delete: "Delete",
        modificaPersonalizzazione: "Edit personalization",
        clonaPersonalizzazione: "Clone personalization",
        uploadImage: "Upload Your Image",
        deleteAll: "Delete All",
        sendCode: "Send me the Code",
        backToSelection: "BACK TO DESIGN AND MATERIAL SELECTION PAGE",
        centerH: "Center horizontally",
        centerV: "Center vertically",
        fit: "Fit size",
    },
    instructions: {
        colors: "Select among the available colors:",
        frames: "Select among the available frames:",
        jack: "Drag jacks at the desired position (jacks are placeholders for touch commands):",
        laser: "Laser printing allow to engrave the plate.",
        print: "Digital printing uses inks on the plate.",
        trascina: "Drag symbols into the placeholders:",
        congrats: "Congrats",
        modified: "You edited your personalization.",
        completed: "You completed your personalization.",
        warning: "Warning",
        lowRes: "some images have a low resolution.",
        note: "You can add any note for this plate:",
        notePlaceholder: "Write here your notes...",
        layout: "Select among the available layouts:",
        prepara: "Prepare image for laser engraver",
        modifica: "Edit the plate and replace it in the archive",
        clona: "Do not replace the plate, copy and add it to the archive",
    },
    cart: {
        riepilogo: "Archive summary",
        alreadyOrdered: "Archive already ordered",
        note: "Notes",
        preview: "Preview",
        name: "Name",
        qta: "Quantity",
        info: "For further information regarding the times and costs of all customizations, including those not present in the cover plate customization software, contact the area sales representative <1 href=\"http://www.ave.it/rete-commerciale\" target=\"_blank\" rel=\"noopener noreferrer\">www.ave.it/rete-commerciale</1> or visit the website <2 href=\"http://ave.it\" target=\"_blank\" rel=\"noopener noreferrer\">www.ave.it</2> in the appropriate commercial catalog section.",
        salvato: "Archive Saved!",
        salvatoCodice: "Congratulations you have saved the archive. Use the following code to change your customizations:",
        compila: "Fill in your details to receive a reminder with the code by email:",
        istruzioni1: "In a few moments you will receive an email with the code of the archive you have saved.",
        istruzioni2: "The email is sent with an automatic program and takes a maximum of 10 minutes to arrive.",
        istruzioni3: "If you still don't see it after 10 minutes then it means that:",
        istruzioni4: "- You have entered an incorrect or non-existent email address",
        istruzioni5: "- The email ended up in spam",
        istruzioni6: "- If you use gmail take a look in the \"Promotions\" tab",
        istruzioni7: "Check your inbox. See you soon!",
    },
    form: {
        nome: "Firstname",
        cognome: "Lastname",
        nomeCliente: "Customer Firstname",
        cognomeCliente: "Customer Lastname",
        email: "Email",
        numeroTelefono: "Telephone number",
        via: "Street and street number",
        localita: "Locality",
        provincia: "State",
        cap: "Zip code",
        nazione: "Country",
        note: "Notes",
        acconsento: "I consent to the processing of my personal data pursuant to Art. 13 EU Regulation 2016/679.",
        leggi: "Read our",
        privacyPolicy: "Privacy Policy",
    },
    messages: {
        requestSent: "Request Sent",
        requestWarning: "Attention: the customization request will be processed and produced on the basis of the drawing confirmed by the configurator.",
        orderSent: "Congratulations you have sent your request!",
        emailinstructions: "Once you have received the email, print it and take it to your wholesaler or official.",
        tecnopolimero44landing: "For the customization of the Technopolymer 44 plates, please contact AVE at the following addresses:",
    },
    ispirazioni: {
        title: "Select Design and Material",
        subtitle: "click on the plate you'd like to customize",
        placche: {
            vetro: "Glass Plates",
            alluminio: "Aluminium Plates",
            corian: "Corian Plates",
            tecnopolimero: "Tecnopolymer Plates",
        },
    },
    plate: {
        placca: "Plate",
        placche: "Plates",
        comando: "Command",
        materiale: "Material",
        modello: "Model",
        serie: "Series",
    },
    catalogo: {
        seleziona: "Select the Plate model",
        click: "click on the item you want to customize",
    },
    menu: {
        privacy: "Privacy Policy",
        cookie: "Cookie Consent",
        contacts: "Contacts",
    },
    dictionary: {
        series: {
            AVETOUCH: 'Ave Touch',
            NEWSTYLE44: 'New Style 44',
            TECNOPOLIMERO44: 'Tecnopolimero 44',
            VERA44: 'Vera 44',
            YOUNG44: 'Young 44',
            YOUNGTOUCH: 'Young Touch',
        },
        materials: {
            ALLUMINIO: 'Alluminium',
            CORIAN: 'Corian',
            TECNOPOLIMERO: 'Tecnopolymer',
            VETRO: 'Glass',
        },
        types: {
            FINESTRA: 'Traditional',
            LEVETTA: 'Lever',
            PRESETONDE: 'Wall sockets',
            TOUCH: 'Touch',
        },
        dimensions: {
            '1MOD': '1 Command',
            '2MOD': '2 Commands',
            '3MOD': '3 Commands',
            '3/6MOD' : '3/6 Commands',
            '4MOD': '4 Commands',
            '6MOD': '6 Commands',
            '7MOD': '7 Commands',
            '8MOD': '8 Commands',
        },
        boxes: {
            '2MOD': '2 Modules Box',
            '3MOD': '3 Modules Box',
            '4MOD': '4 Modules Box',
            '6(3+3)MOD' : '6 (3+3) Modules Box',
            '7MOD': '7 Modules Box',
        },
        layouts: {
            CC: 'WITH FRAME',
            SC: 'WITHOUT FRAME',
        },
    },
    attributes: {
        ave: "AVE S.p.a.",
        originale: "Original",
        elaborata: "Processed",
        soglia: "Treshold",
    },
    contacts: {
        ufficiCommerciali: "Sales Office Italy",
        infoTecniche: "Technical information",
        exportDept: "Export Department",
    },
};