import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import classNames from 'classnames';
import {DRAG_TYPES} from '../../constants';

const WindowCell = ({canDrop, column, onDrop, row}) => {
    const [{item, ok, ko}, drop] = useDrop({
        accept: DRAG_TYPES.JACK,
        canDrop: (item) => canDrop(item, column, row),
        drop: (item) => onDrop(item, column, row),
        collect: monitor => ({
            item: monitor.getItem(),
            ok: !!monitor.isOver() && !!monitor.canDrop(),
            ko: !!monitor.isOver() && !monitor.canDrop(),
		}),
    });
    return (
        <div ref={drop} className="window-cell">
            {item && <div className={classNames('window-cell__preview', {ok, ko})} style={{width: `${item.colspan * 100}%`}} />}
        </div>
    );
};

WindowCell.propTypes = {
    canDrop: PropTypes.func.isRequired,
    column: PropTypes.number.isRequired,
    onDrop: PropTypes.func.isRequired,
    row: PropTypes.number.isRequired,
};

export default WindowCell;
