import React from 'react';
import {useTranslation} from "react-i18next";

const ContactsOffices = () => {
    const {t} = useTranslation();
    return (
        <div className="col">
            <p><strong><span className="testo5">{t('contacts.ufficiCommerciali')}</span></strong><br />
            Ph.+39 030 24981<br />
            Fax +39 030 2792837</p>
        </div>
    );
};

export default ContactsOffices;
