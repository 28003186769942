import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {cartNew, cartUpdateItem, cartDeleteItem} from '../actions/cartActions';
import CartItem from './cart/CartItem';
import CartLayout from './cart/CartLayout';
import {BASE_URL} from '../constants';

const Cart = ({cart, cartUpdateItem, cartDeleteItem, cartEditItem, cartNew}) => {
    const {t} = useTranslation();
    return (
        <CartLayout>
            <div className="mt-2">
                {cart.ordered && <p>{t('cart.alreadyOrdered')}</p>}
                {cart.orderNotes && <p>{t('cart.note')}: {cart.orderNotes}</p>}

            </div>
            <table className="table cart-table">
                <thead>
                    <tr>
                        <th scope="col">{t('cart.preview')}</th>
                        <th scope="col">{t('cart.name')}</th>
                        <th scope="col">{t('cart.qta')}</th>
                        <th scope="col">{t('cart.note')}</th>
                        {!cart.ordered && <th scope="col">{t('buttons.edit')}</th>}
                        {!cart.ordered && <th scope="col">{t('buttons.delete')}</th>}
                    </tr>
                </thead>
                <tbody>
                    {cart.items.map((item) => <CartItem key={item.id} id={item.id} plate={item.plate} note={item.data.note} cartCode={cart.code} quantity={item.quantity}
                        onUpdateItem={cartUpdateItem} onDeleteItem={cartDeleteItem} onEditItem={cartEditItem} disabled={cart.ordered} />)}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6">
                            {!cart.ordered && 
                                <Link to="/ispirazioni" className="btn btn-secondary btn-ave">
                                    {t('buttons.newRequest')}
                                </Link>}
                            {cart.ordered && <>
                                <button className="btn btn-secondary btn-ave" onClick={() => cartNew()}>
                                    {t('buttons.empty')}
                                </button>
                                <button className="btn btn-primary btn-ave" onClick={() => cartNew({clone: true})}>
                                    {t('buttons.clone')}
                                </button>
                                <a href={`${BASE_URL}/cart/pdf/${encodeURIComponent(cart.code)}`} className="btn btn-secondary btn-ave" download target="_self">
                                    {t('buttons.downloadPdf')}
                                </a>
                            </>}
                            {!cart.ordered && cart.items.length > 0 && <>
                                <Link to="/carrello/codice" className="btn btn-secondary btn-ave">
                                    {t('buttons.save')}
                                </Link>
                                <Link to="/carrello/conferma" className="btn btn-primary btn-ave">
                                    {t('buttons.sendRequest')}
                                </Link>
                            </>}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </CartLayout>
    );
};

export default connect(undefined, {cartNew, cartUpdateItem, cartDeleteItem})(Cart);
