import React from 'react';
import {useTranslation} from "react-i18next";
import * as images from '../../../images';

const ToolbarFileButton = ({onChange}) => {
    function handleChangeFile(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            onChange(e.target.result);
        };
        reader.readAsDataURL(file);
        e.target.value = null;
    }

    const {t} = useTranslation();

    return (
        <div className="file-tool-button">
            <input type="file" accept="image/png, image/jpeg" onChange={handleChangeFile} id="content-tools_upload" />
            <label className="file-tool-button" htmlFor="content-tools_upload">
                <span className="image"><img src={images.file} alt={t("buttons.uploadImage")} /></span>
                <div>{t("buttons.uploadImage")}</div>
            </label>
        </div>
    );
};

export default ToolbarFileButton;
