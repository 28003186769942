import React from 'react';

const Privacy = () => (
    <div className="container">
        <h1 className="text-center mt-5 mb-4">Norme sulla Privacy</h1>
        <p><strong>INFORMATIVA AL TRATTAMENTO DEI DATI PERSONALI A SENSI DELLA NORMATIVA VIGENTE</strong></p>
        <p><strong>1) TITOLARE E DATI DI CONTATTO: </strong>il titolare del trattamento è la società Ave S.p.A. in persona del legale rappresentante pro tempore. La società ha sede legale in Via Mazzini 75, 25086 Rezzato (Brescia) email <a href="mailto:privacy@ave.it">privacy@ave.it</a>.</p>
        <p><strong>2) FINALITA’ DEL TRATTAMENTO E SUA BASE GIURIDICA: </strong>i Suoi dati personali saranno acquisiti e trattati, con il Suo consenso ove necessario, per garantire l’iscrizione ai siti web di Ave S.p.A. e per l’erogazione dei contenuti e servizi offerti tramite detti siti web o le piattaforme online ad essi collegate, per effettuare indagini di mercato e rilevazioni statistiche riconducibili ad attività di marketing, per finalità di marketing diretto, sia strategico che operativo ivi compreso l’invio di materiale pubblicitario e tecnico promozionale (cataloghi, newsletter, partecipazione a community di vario genere) via email, sms, wathsapp notifiche push, applicazioni mobile, posta cartacea, telefono con operatore, per finalità di rilevazione del grado di soddisfazione sulla qualità dei prodotti e servizi, per finalità&nbsp; legate alla stipula e all’esecuzione di contratti di vendita, per finalità di sicurezza informatica&nbsp; e per adempiere ogni tipo di obbligo previsto da leggi o regolamenti. Il conferimento dei dati personali è libero e facoltativo. Tuttavia il mancato conferimento dei dati comporta l’impossibilità di dar corso alle iscrizione ai siti web e&nbsp; conseguentemente di ricevere i servizi richiesti, le informazioni, le comunicazioni, materiale pubblicitario e tecnico promozionale. Il trattamento dei dati per le finalità sopra indicate trova la sua base giuridica nell’art. 6 comma 1 lett. a (consenso), lett. b (esecuzione obblighi precontrattuali e contrattuali), lett. c (adempiere obbligo legale) lett. f (legittimo interesse) Reg. UE 2016/679.</p>
        <p><strong>3) CATEGORIE DI DATI PERSONALI: </strong>sono raccolti e oggetto di trattamento dati personali di tipo identificativo, anagrafico o commerciale (quali ad esempio: nome, cognome, indirizzo email). Non sono raccolti dati personali sensibili (quali ad esempio dati relativi alla salute o ad opinioni politiche) né dati giudiziari. Sono raccolti anche dati di navigazione e/o interazione acquisiti dai sistemi informatici e dalle procedure software di questi siti web e/o da software terzi (a titolo di esempio: indirizzi IP, orario della richiesta). Questi dati sono utilizzati allo scopo di confrontare ed eventualmente migliorare i risultati delle comunicazioni.</p>
        <p><strong>4) LEGITTIMI INTERESSI DEL TITOLARE DEL TRATTAMENTO: </strong>il trattamento dei Suoi dati personali è necessario per l’esercizio delle finalità di cui sopra. In particolare costituisce legittimo interesse di Ave S.p.A. il trattamento dei Suoi dati per finalità di marketing diretto e per finalità di sicurezza informatica. Il trattamento dei Suoi dati verrà effettuato con strumenti manuali, informatici e telematici idonei a garantire la sicurezza e la riservatezza dei Suoi dati personali prevenendone la perdita, gli usi illeciti o non corretti, gli accessi non autorizzati. Il trattamento dei Suoi dati sarà consentito solo a persone designate Responsabili del Trattamento, Delegati e/o Incaricati del trattamento. La raccolta, la trasmissione, e l’archiviazione e la conservazione&nbsp; dei Suoi dati personali è protetta tramite utente e password e da protocolli di criptatura con certificati web SSL, da sistemi di protezione anti brute force brutal force e captcha. I Suoi dati personali sono conservati in archivi elettronici protetti presso server in uso ad Ave S.p.A. e dislocati sia presso Ave S.p.A. sia presso provider. I servizi utilizzati per comunicare, promuovere, archiviare o gestire dati vengono protetti da protocolli SSL e da appositi sistemi di protezione.</p>
        <p><strong>5) DESTINATARI DEL TRATTAMENTO: </strong>fermo restando quanto da Lei autorizzato alle Sue app, i Suoi dati personali non verranno in alcun modo diffusi, ma potranno essere comunicati a soggetti che agiscono come Responsabili del Trattamento (quali ad esempio: agenti di Ave S.p.A., Centro Assistenza Tecnica di Ave S.p.A., società o ditte che effettuano manutenzione, gestione e consulenze ai sistemi informativi di Ave S.p.A.) Delegati del Trattamento e/o come Incaricati del Trattamento (dipendenti di Ave S.p.A.). I suoi dati La comunicazione a soggetti diversi potrà avvenire solo ove sia necessaria per ottemperare agli obblighi di legge o per la tutela di diritti contrattuali di Ave S.p.A. L’elenco dei Responsabili del Trattamento è disponibile presso la sede legale di Ave S.p.A.</p>
        <p><strong>6) PERIODO CONSERVAZIONE DATI:</strong> i Suoi dati personali saranno trattati con strumenti manuali, informatici e telematici e saranno conservati per il tempo necessario per perseguire le finalità sopradescritte e garantire diligentemente la prestazione dei servizi da Lei richiesti a meno che non sia necessario o consentito dalla legge un periodo di tempo più lungo.</p>
        <p><strong>7) DIRITTI DELL’INTERESSATO:</strong> Lei 1) ha diritto in qualunque momento di sapere se è in corso o meno un trattamento dei Suoi dati personali ed in caso positivo chiedere ed ottenere l’accesso ai Suoi dati personali e alle informazioni relative alla finalità del trattamento, alle categorie dei dati trattati, ai destinatari degli stessi, al periodo di conservazione; inoltre&nbsp; ha diritto ad&nbsp; avere informazioni sull’origine dei dati ed ad averne una copia (art. 15 Reg. UE); 2) ha diritto in qualunque momento di chiedere la rettifica e/o l’integrazione dei dati personali (art.16 Reg. UE) e/o la cancellazione degli stessi (art. 17 Reg. Ue) e/o la limitazione del trattamento (art.18 Reg. UE); 3) ha diritto alla portabilità dei dati (art. 20 Reg. UE); 4) ha diritto di opporsi al trattamento (art. 21 Reg. UE); 5) ha diritto di proporre reclamo alla autorità di controllo competente (Garante per la protezione dei dati personali) qualora ritenga che il trattamento dei Suoi dati personali sia contrario alla normativa in vigore. Le richieste vanno rivolte per scritto via posta ad Ave S.p.A., Via Mazzini 75 25086 Rezzato (Brescia) alla attenzione della Segreteria Generale o via email a <a href="mailto:privacy@ave.it">privacy@ave.it</a>.</p>
        <p><strong>8) COOKIE: </strong>per informazioni sui cookies del presente sito web consulta la cookies policy cliccando <a href="/cookie"><strong>qui</strong></a></p>
        <p><strong>Informativa al trattamento dei dati personali pubblicata il 29 gennaio 2018. Ultimo aggiornamento 2 aprile 2019.</strong></p>
    </div>
)

export default Privacy;
