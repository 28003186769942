export default class ImageCache {
    /**
     * @param {function} readyCallback
     */
    constructor(readyCallback) {
        this._cache = {};
        this._readyCallback = readyCallback;
    }

    /**
     * @param {string} src 
     * @returns {HTMLImageElement}
     */
    get(src) {
        if (!this._cache[src]) {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Avoid tainted canvas error https://stackoverflow.com/a/22716873/2564990
            img.src = src;
            img.onload = () => this._readyCallback();
            this._cache[src] = img;
        }
        return this._cache[src];
    }
}
