import React from 'react';
import {withTranslation, Trans} from "react-i18next";

const CartLayout = ({children, t}) => (
    <div className="container">
        <div className="mt-5">
            <p className="text-center text-uppercase"><strong>{t('cart.riepilogo')}</strong></p>
        </div>
        {children}
        <div className="mt-5 text-center">
            <p className="font-weight-bold">
                <Trans i18nKey="cart.info">
                    Per ulteriori informazioni ... <a href="http://www.ave.it/rete-commerciale" target="_blank" rel="noopener noreferrer">www.ave.it/rete-commerciale</a> o
                    visitare il sito <a href="http://ave.it" target="_blank" rel="noopener noreferrer">www.ave.it</a> nella apposita sezione catalogo commerciale.
                </Trans>
            </p>
        </div>
    </div>
);

export default withTranslation()(CartLayout);
