import axios from 'axios';
import {BASE_URL} from './constants';

const client = axios.create({withCredentials: true});

export const cartGetCurrent = () => client.get(`${BASE_URL}/cart/current`).then(({data}) => data);
export const cartSetCurrent = (code) => client.post(`${BASE_URL}/cart/current`, {code}).then(({data}) => data);
export const cartNew = ({clone}) => client.post(`${BASE_URL}/cart/new`, {clone}).then(({data}) => data);
export const cartAdd = (values) => client.post(`${BASE_URL}/cart/item`, values).then(({data}) => data);
export const cartGetItem = (itemId) => client.get(`${BASE_URL}/cart/item/${encodeURIComponent(itemId)}`).then(({data}) => data);
export const cartUpdateItem = (itemId, values) => client.put(`${BASE_URL}/cart/item/${encodeURIComponent(itemId)}`, values).then(({data}) => data);
export const cartDeleteItem = (itemId) => client.delete(`${BASE_URL}/cart/item/${encodeURIComponent(itemId)}`).then(({data}) => data);
export const cartPlaceOrder = (values) => client.post(`${BASE_URL}/cart/place-order`, values).then(({data}) => data);
export const cartSave = (values) => client.post(`${BASE_URL}/cart/save`, values).then(({data}) => data);

export const catalogGetPlate = (code) => client.get(`${BASE_URL}/catalog/plates/${encodeURIComponent(code)}`).then(({data}) => data);
export const catalogGetPlateList = () => client.get(`${BASE_URL}/catalog/plates`).then(({data}) => data);

export const getCartItemThumbnailUrl = (cartCode, cartItemId) =>
    `${BASE_URL}/cart/thumbnail/${encodeURIComponent(cartCode)}/${encodeURIComponent(cartItemId)}`;
