import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import Footer from './Footer';
// import * as images from '../images';

class Intro extends React.Component {
    render() {
        const { i18n } = this.props;
        return (
            <>
                <Link to={`/ispirazioni`}>
                    <div className={`intro-container ${i18n.language}`}>
                        {/*<h1 className="display-4">Configura la tua placca</h1>
                        <p><img src={images.logo} alt="AVE S.p.a." /></p>
                        <p className="lead">con simbologie, marchi e loghi adatti alle tue esigenze</p>
                        <hr className="my-4" />
                        <p>- - - - - INIZIA LA TUA PERSONALIZZAZIONE - - - - -</p>
                        <p className="lead">entra</p>*/}
                    </div>
                </Link>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(Intro);
