import React from 'react';
import {useTranslation} from "react-i18next";
import ContactsExport from './ContactsExport';
import ContactsOffices from './ContactsOffices';
import ContactsTechnicalinfo from './ContactsTechnicalinfo';

const Landing44PY = () => {
    const {t} = useTranslation();
    return (
        <div className="container">
            <h1 className="text-center mt-5 mb-4">{t("dictionary.series.TECNOPOLIMERO44")}</h1>

            <div className="row text-center">
                <div className="col">
                    <img src="/plate-types/t2.png" alt={t("dictionary.series.TECNOPOLIMERO44")}/>
                </div>
            </div>

            <div className="row text-center">
                <div className="col">
                    <p><strong>{t("messages.tecnopolimero44landing")}</strong></p>
                </div>
            </div>
            <div className="row text-center">
                <ContactsOffices />
                <ContactsTechnicalinfo />
                <ContactsExport />
            </div>

            <div className="row text-center">
                <div className="col">
                    <a className="btn btn-primary btn-ave" href="/ispirazioni">{t("buttons.backToSelection")}</a>
                </div>
            </div>
            
        </div>
    );
};

export default Landing44PY;
