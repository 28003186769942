import React from 'react';
import {Field, Formik, Form} from 'formik';
import {useTranslation} from "react-i18next";

const PlaceOrderForm = ({onSubmit}) => {
    const {t} = useTranslation();
    return (
        <Formik initialValues={{email: '', firstname: '', lastname: '', phone: '', address: '', city: '', state: '', zip: '', country: '', note: ''}} onSubmit={onSubmit}>
            <Form className="ave-form">
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_firstname">{t('form.nomeCliente')} *</label>
                        <Field className="form-control" type="text" id="placeorder_firstname" name="firstname" required />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_lastname">{t('form.cognomeCliente')} *</label>
                        <Field className="form-control" type="text" id="placeorder_lastname" name="lastname" required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="placeorder_email">{t('form.email')} *</label>
                        <Field className="form-control" type="email" id="placeorder_email" name="email" required  />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="placeorder_phone">{t('form.numeroTelefono')} *</label>
                        <Field className="form-control" type="text" id="placeorder_phone" name="phone" required  />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="placeorder_address">{t('form.via')} *</label>
                        <Field className="form-control" type="text" id="placeorder_address" name="address" required  />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_city">{t('form.localita')} *</label>
                        <Field className="form-control" type="text" id="placeorder_city" name="city" required />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_state">{t('form.provincia')} *</label>
                        <Field className="form-control" type="text" id="placeorder_state" name="state" required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_zip">{t('form.cap')} *</label>
                        <Field className="form-control" type="text" id="placeorder_zip" name="zip" required />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="placeorder_country">{t('form.nazione')} *</label>
                        <Field className="form-control" type="text" id="placeorder_country" name="country" required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="placeorder_notes">{t('form.note')}</label>
                        <Field className="form-control" component="textarea" rows="5" id="placeorder_notes" name="note" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="placeorder_tos" required />
                        <label className="form-check-label" htmlFor="placeorder_tos">
                            {t('form.acconsento')}<br />
                            {t('form.leggi')} <a href="/privacy" target="_blank">{t('form.privacyPolicy')}</a>
                        </label>
                    </div>
                </div>
                <button className="btn btn-primary btn-ave" type="submit">{t('buttons.sendRequest')}</button>
            </Form>
        </Formik>
    );
};

export default PlaceOrderForm;
