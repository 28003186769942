export const BASE_URL = process.env.REACT_APP_BASE_URL;
if (!BASE_URL) {
    throw new Error('REACT_APP_BASE_URL env variable is not defined.');
}

export const ASSET_URL = process.env.REACT_APP_ASSET_URL;
if (!ASSET_URL) {
    throw new Error('REACT_APP_ASSET_URL env variable is not defined.');
}

export const assetUrl = (url) => url ? `${ASSET_URL}${url}` : url;

export const VERSION_LABEL = process.env.REACT_APP_VERSION_LABEL || 'dev';
