import React from 'react';
import {useTranslation} from "react-i18next";

const ContactsExport = () => {
    const {t} = useTranslation();
    return (
        <div className="col">
            <p><strong><span className="testo5">{t('contacts.exportDept')}</span></strong><br />
            Ph.+39 030 24981<br />
            E-mail: <a className="testo5" href="mailto:export@ave.it">export@ave.it </a></p>
        </div>
    );
};

export default ContactsExport;
