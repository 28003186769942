import React from 'react';
import find from 'lodash/find';
import * as images from '../../images';
import WindowCell from './WindowCell';
import JackPreview from './JackPreview';

class WindowEditor extends React.Component {
    constructor(props) {
        super(props);
        this.canDrop = this.canDrop.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.renderJack = this.renderJack.bind(this);
    }

    canDrop({code}, col) {
        const {availableJacks, configBuilder, window} = this.props;
        const {colspan} = find(availableJacks, {code});
        return (col + colspan) <= configBuilder.finestre[window].columns;
    }

    handleDrop({code}, col) {
        const {availableJacks, jacks, window} = this.props;
        const jack = find(availableJacks, {code});
        const colEnd = col + jack.colspan;
        const remainingJacks = jacks.filter((j) => j.window !== window || colEnd <= j.col || col >= j.col + j.colspan); // remove overlapping jacks
        const newJack = {
            code,
            col,
            colspan: jack.colspan,
            window,
        };
        this.props.onChange({jacks: [...remainingJacks, newJack]});
    }

    renderJack({code, col, colspan}, index) {
        const jack = this.props.availableJacks.find(({code: c}) => code === c);
        const handleDelete = () => {
            const jacksInWindow = this.props.jacks.filter(({window: jackWindow}) => jackWindow === this.props.window);
            this.props.onChange({jacks: this.props.jacks.filter((j) => j !== jacksInWindow[index])});
        };
        return (
            <div className="window-editor__jack-wrapper" style={{left: `${col / this.props.columns * 100}%`}} key={index}>
                <JackPreview width={jack.width} height={jack.height} ratio={this.props.ratio} placeholders={jack.placeholders} />
                <button className="delete-button" onClick={handleDelete}><img src={images.delete} alt="D" /></button>
            </div>
        );
    }

    render() {
        const {availableJacks, columns, configBuilder, jacks, window, ...rest} = this.props;
        return (
            <div {...rest} className="window-editor">
                <div className="window-editor__jacks-container">
                    {jacks.filter(({window: jackWindow}) => jackWindow === window).map(this.renderJack)}
                </div>
                <div className="window-editor__cells-container">
                    {[...Array(columns)].map((x, i) => (
                        <WindowCell key={i} canDrop={this.canDrop} onDrop={this.handleDrop} column={i} row={0} />
                    ))}
                </div>
            </div>
        );
    }
}

export default WindowEditor;
