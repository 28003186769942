import React from 'react';
import * as images from '../../../images';

const ToolbarButtonImage = ({image}) => (<span className="image"><img src={images[image]} alt={image} /></span>);
const ToolbarButtonLabel = ({children}) => (<span className="label">{children}</span>);

const ToolbarButton = ({...rest}) => (
    <div className="cfg-toolbar-btn" {...rest} />
);

ToolbarButton.Image = ToolbarButtonImage;
ToolbarButton.Label = ToolbarButtonLabel;

export default ToolbarButton;
