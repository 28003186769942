import {all, fork} from 'redux-saga/effects';
import cartSagas from './cartSagas';
import catalogSagas from './catalogSagas';

export default function* rootSaga() {
    yield all([
        fork(cartSagas),
        fork(catalogSagas),
    ]);
}
