import React from 'react';
import {useTranslation} from "react-i18next";
import PlaceOrderForm from './PlaceOrderForm';

const PlaceOrderConfirm = ({onSubmit}) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="mt-5 text-center">
                <div className="alert alert-dark" role="alert">
                    {t('messages.requestSent')}
                </div>

                <div className="alert alert-warning" role="alert">
                    {t('messages.requestWarning')}
                </div>
            </div>
            <div className="mt-5 row justify-content-center">
                <div className="col-8">
                    <PlaceOrderForm onSubmit={onSubmit} />
                </div>
            </div>
        </>
    );
};

export default PlaceOrderConfirm;
