import React, {useRef, useState} from 'react';
import {useDrop} from 'react-dnd';
import {DRAG_TYPES} from '../../constants';
import {generateId} from '../../lib/utils';

const DroppableArea = ({area, onDrop, onHover, type, ratio, snap, id, ...rest}) => {
    const ref = useRef(null);
     const [rect, setRect] = useState([0, 0, 0, 0]);

    function getCoords(monitor) {
        const clientOffset = monitor.getClientOffset();
        if (!ref.current || !clientOffset) {
            return [0, 0];
        }
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const destX = clientOffset.x - hoverBoundingRect.left;
        const destY = clientOffset.y - hoverBoundingRect.top;
        return [destX - item.size[0] / 2 / item.dpmm, destY - item.size[1] / 2 / item.dpmm];
    }

    const [{isOver, item}, drop] = useDrop({
        accept: [DRAG_TYPES.IMAGE, DRAG_TYPES.ITEM],
        drop: (item) => {
            const pos = snap ? [area[0], area[1]] : [area[0] + rect[0] / ratio, area[1] + rect[1] / ratio];
            const size = snap ? [area[2], area[3]] : [item.size[0] / ratio / item.dpmm, item.size[1] / ratio / item.dpmm];
            onDrop(Object.assign({}, item, {areaType: type, pos, size, area, areaId: id, id: item.id || generateId(), naturalSize: item.size}));
        },
        hover: (item, monitor) => {
            if (monitor.canDrop()) {
                if (snap) {
                    setRect([0, 0, area[2] * ratio, area[3] * ratio]);
                } else {
                    setRect(getCoords(monitor).concat([item.size[0] / item.dpmm, item.size[1] / item.dpmm]));
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.canDrop() && monitor.isOver(),
            item: monitor.getItem(),
        }),
    });
    drop(ref);
    return (
        <div ref={ref} className="cfg__droppable-area" {...rest}>
            {isOver && <img src={item.src} alt="" width={rect[2]} height={rect[3]} className={`cfg__droppable-area__image-type-${type}`}
                style={{position: 'absolute', top: rect[1], left: rect[0]}} />}
        </div>
    );
};

export default DroppableArea;
