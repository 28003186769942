import React from 'react';

const Privacy = () => (
    <div className="container">
        <h1 className="text-center mt-5 mb-4">Utilizzo dei Cookie</h1>
        <p>I cookie sono piccoli file che memorizzano informazioni sul computer, il TV, il telefono cellulare o gli altri dispositivi dell'utente. Questi file permettono al soggetto che li ha immessi nel dispositivo di riconoscere l'utente su diversi siti web, servizi, dispositivi e/o sessioni di navigazione. I cookie sono utili per varie ragioni. Ad esempio:</p>
        <p>•&nbsp;&nbsp; &nbsp;Possono memorizzare le credenziali di accesso dell'utente, per evitare che debba immetterle ogni volta che si collega a un servizio.<br />
        •&nbsp;&nbsp; &nbsp;Consentono a Ave e a terze parti di comprendere quali parti dei Servizi sono più popolari, poiché grazie ad essi è possibile sapere quali sono le pagine e le funzioni a cui accedono i visitatori e quanto tempo trascorrono nella loro consultazione. Studiando questo tipo di informazioni, Ave riesce ad adattare meglio i Servizi e a fornire all'utente un'esperienza migliore.<br />
        •&nbsp;&nbsp; &nbsp;Permettono a Ave e alle terze parti di comprendere quali inserzioni pubblicitarie sono già state visualizzate, per evitare che l'utente riceva sempre le stesse ogni volta che accede a un servizio. Aiutano Ave e le terze parti a fornire contenuti e pubblicità più pertinenti, grazie alle informazioni raccolte sull'utilizzo che l'utente fa dei Servizi, degli altri siti web e delle applicazioni.</p>
        <p>In questo sito internet vengono utilizzati solo cookie tecnici, cioè quelli utilizzati al solo fine di effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica,o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio. &nbsp;(cfr. art. 122, comma 1, del Codice).&nbsp;</p>
        <p>Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito &nbsp;web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate); &nbsp;cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere &nbsp;informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad &nbsp;esempio, la lingua, i prodotti selezionati per l'acquisto) al fine di migliorare il servizio reso allo stesso.&nbsp;</p>
        <p>Fatta questa premessa, si ribadisce che nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, né vengono utilizzati c.d. cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti. L’uso di c.d. cookies di sessione (che non vengono memorizzati in modo persistente sul computer dell’utente e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di &nbsp;sessione (costituiti da numeri casuali generati dal server) necessari per consentire l’esplorazione sicura ed efficiente del sito. I c.d. cookies di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l’acquisizione di dati personali identificativi dell’utente. Non sono utilizzate in alcun modo tecniche informatiche per l’acquisizione diretta di dati personali &nbsp;identificativi dell’utente.&nbsp;<br />
        &nbsp;<br />
        I c.d. cookies di sessione, una volta terminata la connessione al sito web non vengono conservati. I sistemi informatici non utilizzano pertanto cookies per la trasmissione di informazioni di carattere personale ne cookies persistenti di alcun tipo. Per ogni informazione o delucidazione scrivere a: <a href="mailto:info@ave.it">info@ave.it</a>.&nbsp;</p>
        <p>Quando l'utente utilizza un browser web per accedere ai Servizi, può configurarlo in modo che accetti tutti i cookie, che li rifiuti tutti o che notifichi quando ne viene inviato uno. Ogni browser è diverso, perciò, per sapere come modificare le preferenze relative ai cookie, l'utente deve consultare il menu "Guida" di quello che utilizza. Il sistema operativo del dispositivo dell'utente può prevedere anche altri controlli relativi ai cookie.</p>
        <p>Alcuni Servizi, tuttavia, possono essere progettati per funzionare tramite i cookie, perciò se l'utente sceglie di disattivarli non sarà più possibile utilizzarli, in tutto o in parte.</p>
        <p> Beacon </p>
        <p>Ave, insieme ad alcune terze parti, può inoltre avvalersi di tecnologie chiamate beacon (o "pixel"), che comunicano informazioni dal dispositivo a un server. I beacon possono essere integrati in contenuto online, video e messaggi di posta elettronica e permettono a un server di leggere alcuni tipi di informazioni dal dispositivo, sapere quando l'utente ha visualizzato un contenuto o un messaggio e-mail particolare, stabilire la data e l'ora in cui ha visualizzato il beacon e l'indirizzo IP del dispositivo. Ave e alcune terze parti utilizzano i beacon per vari scopi, ad esempio per analizzare l'utilizzo dei Servizi da parte dell'utente e, in abbinamento con i cookie, per fornire contenuto e inserzioni pubblicitarie più mirati.</p>
        <p>Con l'accesso e l'utilizzo dei Servizi, l'utente acconsente alla memorizzazione di cookie e di altre tecnologie di memorizzazione in locale, beacon e altro sui propri dispositivi. L'utente acconsente altresì all'accesso a tali cookie, tecnologie di memorizzazione in locale, beacon e informazioni da parte di Ave e di altre terze parti come descritto in precedenza.</p>
        <p> Plugin social network </p>
        <p>I servizi Ave fanno uso di plugin social network ("Plugins"). Quando si utilizza un servizio che contiene Plugins, le informazioni possono essere trasferite direttamente dal dispositivo dell’utente al gestore della rete sociale. Ave non ha alcuna influenza sui dati raccolti dal plugin. Se l’utente è connesso nella rete sociale, l'utilizzo del servizio Ave può fare riferimento al vostro account di social network. Se si interagisce con il Plugin, ad esempio facendo clic su "Mi piace", "Segui" o "Share", o si inserisce un commento, l'informazione può essere mostrare automaticamente nel profilo del social network. Anche se l’utente non è connesso al proprio account di social network, potrebbe essere possibile che i Plugins trasmettano l’indirizzo IP dell0utente agli operatori di social network. Si prega di considerare quanto sopra detto quando si utilizzano i servizi Ave.</p>
        <p>Per informazioni sugli operatori della rete sociale di plugin utilizzati nei servizi Ave ("Operatori"), si prega di vedere sotto</p>
        <p>Gli operatori di plugin utilizzati nei Servizi Ave</p>
        <p>Se l’utente è un membro di uno o più dei seguenti social network e non vuole che il Gestore ricolleghi i dati riguardanti l'uso dei servizi Ave con i propri dati già memorizzati dal Gestore, è pregato di disconnettere il social network prima di utilizzare i Servizi Ave.</p>
        <p>Facebook</p>
        <p>Titolare dei dati: Facebook Ireland Limited, Hanover Reach, 5-7 Hanover Quay, 2 Dublin, Ireland (“Facebook”). Per maggiori informazioni l’utente può visitare “Facebook’s privacy policy website al seguente indirizzo <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.</p>
        <p>Google+</p>
        <p>Titolare dei dati: Google Inc., Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Per maggiori informazioni l’utente può prendere vision della privacy policy di Google al seguente indirizzo <a href="http://www.google.de/intl/de/policies/terms/regional.html">http://www.google.de/intl/de/policies/terms/regional.html</a>.</p>
        <p>LinkedIn</p>
        <p>Titolare dei dati: LinkedIn Ireland, Gardner House, Wilton Place, Wilton Plaza, Dublin 2, Ireland (“LinkedIn”). Per maggiori informazioni l’utente può prendere visione della privacy policy di LinkedIn al seguente indirizzo <a href="http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv">http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv</a>.</p>
        <p>Twitter</p>
        <p>Titolare dei dati: Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA (“Twitter”). Per maggiori informazioni l’utente può prendere visione della privacy policy di Twitter al seguente indirizzo <a href="https://twitter.com/privacy">https://twitter.com/privacy</a>.</p>
    </div>
)

export default Privacy;
